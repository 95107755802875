import { Component, OnInit, Host, ViewChild } from '@angular/core';
import { ApibundleService } from '../../services/apibundle.service';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-repository-attestation',
  templateUrl: './repository-attestation.component.html',
  styleUrls: ['./repository-attestation.component.css']
})
export class RepositoryAttestationComponent implements OnInit {
  err;
  activeRoute: any;
  norecord: boolean = false;
  approvedList;
  loadedkbList;
  isUnAuthorized = false;
  displayedColumns: string[] = ['requestorName', 'managerName', 'requestType', 'timestamp', 'comment', 'action'];
  limit: number = 5;
  requestDataSourceMobile: any;
  isFilterData: boolean = false;
  @ViewChild(MatPaginator) paginators: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  spinner: boolean = false;
  spinnerName = "reqApproval";
  repositoryDetail: any = {};
  repoId: any;
  statusList: any = ['Active', 'Inactive'];
  certStatus = new FormControl('');
  dateCerti = new FormControl(new Date());
  comments = new FormControl('');
  ngForm: FormGroup;
  repoPage: string;
  isDisable: boolean = false;
  commentArray: any = [];
  monthAccess: number[] = [1, 5, 9];
  grantAccess: boolean = false;
  constructor(
    private apibundleService: ApibundleService,
    private broadcastService: BroadcastService,
    private router: Router,
    public location: Location, private route: ActivatedRoute,
    private commonService: CommonService,
    private authService: MsalService,
  ) {
    this.broadcastService.subscribe("msal:loginSuccess", payload => {
      console.log(payload);
    });
  }

  ngOnInit() {
    this.activeRoute = this.route.params.subscribe(params => {
      if (typeof params['repoId'] != "undefined") {
        this.repoId = params['repoId'];
        this.repoPage = params['repoPage'];
        this.fetchRepoDetail();
      }
    });
    if(this.repoPage == "allrepository") {
        this.isDisable = true;
    } 
    if(this.monthAccess.includes(new Date().getMonth()))
      this.grantAccess = true;
    window.scrollTo(0, 0);
  }

  async fetchRepoDetail() {
    this.apibundleService.showSpinner('reqApproval');
    this.spinner = true;
    this.apibundleService.fetchRepoDetail(this.repoId).subscribe(
      res => {
        this.repositoryDetail = res;
        if(this.repositoryDetail.status == '') {
          this.repositoryDetail.status = 'Inactive'
          this.certStatus.setValue('Inactive');
        }
        this.dateCerti.setValue(new Date(this.repositoryDetail.dateOfCertification));
        setTimeout(() => {
          this.apibundleService.hideSpinner('reqApproval');
          this.spinner = false;
        }, 2000);
        if (this.repositoryDetail.length === 0) {
          this.norecord = true;
        } else {
          this.norecord = false;
        }
      },
      err => {
        this.err = err;
        console.log(err);
        this.apibundleService.hideSpinner('reqApproval');
        this.spinner = false;
      });
  }

  async processForm(formData) {
    if(this.isDisable && this.comments.value != '') {
      this.submitComment();
    } else if(this.isDisable) {
      this.commonService.openSnackBar("Please add comments", "OK");
    }
    if(!this.isDisable && this.repositoryDetail.status != this.certStatus.value){
      this.submitAttestation();
    } else if(!this.isDisable) {
      this.commonService.openSnackBar("Please change the status", "OK");
    }
  }

  submitComment() {
    var commentsDetail: any = {};
    var comment: any = {
        "comment": this.comments.value,
        "id": this.repositoryDetail.repoId,
        "commentType": "repo",
        "commentedBy": this.authService.getAccount().userName
    };
    commentsDetail.id = this.repositoryDetail.repoId;
    commentsDetail.comments = [comment];
      this.apibundleService.submitComment(commentsDetail).subscribe(
        res => {
          this.commonService.openSnackBar("Comment has been added sucessfully successfully", "OK");
          this.router.navigate(['/services/githubrepositories']);
        },
        err => {
          this.err = err;
          console.log(err);
        });
  }

  submitAttestation() {
    var comment: any = {
      "comment": this.comments.value,
      "id": this.repositoryDetail.repoId,
      "commentType": "repo",
      "commentedBy": this.authService.getAccount().userName
  };
    var repoAttesDetails: any = {
    "repoId": this.repositoryDetail.repoId,
    "repoName": this.repositoryDetail.repoName,
    "status": this.certStatus.value,
    "dateOfCertification": this.dateCerti.value ,
    "comments": [comment],
    "attestedBy": this.authService.getAccount().name,

    };
      this.apibundleService.submitAttestation(repoAttesDetails).subscribe(
        res => {
          this.commonService.openSnackBar("Comment has been added sucessfully successfully", "OK");
          this.router.navigate(['/services/githubrepositories']);
        },
        err => {
          this.err = err;
          console.log(err);
        });
  }

  doRefresh(event) {
    console.log('Begin async operation');
    this.fetchRepoDetail();
    setTimeout(() => {
      console.log('Async operation has ended');
      event.target.complete();
    }, 2000);
  }

}
