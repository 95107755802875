
    <div class="container">      
        <div class="column">
            <h1>Enabling developers</h1>
            <h1>to build better</h1>
            <h1>software</h1>
            <h4 style="color: #cecece;font-weight: lighter;">
                We are here to help developers and technology</h4>
            <h4 style="color: #cecece;font-weight: lighter;">teams to plan, develop and operate high-quality,</h4>
            <h4 style="color: #cecece;font-weight: lighter;">cost-effective, secure, and reliable software and </h4>
            <h4 style="color: #cecece;font-weight: lighter;">digital products.
            </h4>
        </div>
        <div class="column"><div id="my_dataviz"></div></div>
    </div>
    
    