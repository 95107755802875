<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="m-auto col-md-10 col-lg-10 col-xs-12 col-sm-12">
                <form class="example-form animate__animated animate__zoomIn animate__delay-0.5s" #form="ngForm"
                    (ngSubmit)="processForm(form)">
                    <h5 class="text-left;m-0;">REPOSITORY ATTESTATION</h5>
                    <hr class="mb-4">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Repository Name</span> 
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <mat-label>{{repositoryDetail.repoName}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Certification Status</span> 
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label *ngIf="isDisable && !grantAccess">{{repositoryDetail.status}}</mat-label>
                            <mat-form-field appearance="outline" *ngIf="!isDisable && grantAccess">
                            <mat-select [formControl]="certStatus">
                                <mat-option #userTy *ngFor="let status of statusList" [value]="status">
                                    {{status}}
                                </mat-option>
                            </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Date of Certification</span> 
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <mat-label>{{repositoryDetail.dateOfCertification | date :'medium'}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Comment</span> 
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                            <mat-form-field  class="mt-2" appearance="outline" >
                                <textarea  [readonly]="!grantAccess" [formControl]="comments"matInput placeholder="Add comment"></textarea>
                              </mat-form-field>         
                          <mat-card *ngFor="let comment of repositoryDetail.comments" class="mt-2">
                              <mat-card-header class="mt-2">
                                  <mat-card-subtitle> {{comment.commentedBy}} commented | 
                                    {{comment.commentDate | date : 'medium'}}</mat-card-subtitle>
                                <mat-card-title>{{comment.comment}}</mat-card-title>
                              </mat-card-header>
                            </mat-card>
                        </div>
                    </div>
                    

                    <div class="row mt-4">
                        <div class="col text-center">
                            <button mat-raised-button color="dark" class="back-button"
                                (click)="location.back()">Back</button>&nbsp;&nbsp;&nbsp;
                            <button *ngIf="!isDisable" [disabled]="!grantAccess" mat-raised-button color="warn" style="width : 8em;">Attestation</button>
                            <button *ngIf="isDisable" [disabled]="!grantAccess" mat-raised-button color="warn" style="width : 10em;">Add Comment</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>


