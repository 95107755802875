import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FilterPipe } from './common/filter.pipe';
import { environment } from '../environments/environment';
import { MaterialModule } from './common/material-module';
import { FooterComponent } from './components/footer/footer.component';
import { CoeAuthGuard } from './guards/coe-Auth.guard';
import { CoeAuthInterceptor } from './interceptors/coe-auth.interceptor';
import { CoeAuthService } from './services/coeauth.service';
import { LazyLoadImageModule } from 'ng-lazyload-image';


import { HeaderComponent } from './components/header/header.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { CarsoalComponent } from './components/carsoal/carsoal.component';
import { ServicesComponent } from './components/services/services.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { HomeComponent } from './components/home/home.component';
import { TrainingComponent } from './components/training/training.component';
import { ReqEnvironmentComponent } from './components/req-environment/req-environment.component';

import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxSpinnerModule } from 'ngx-spinner';
import {
  MsalModule,
  MsalInterceptor,
  MSAL_CONFIG,
  MSAL_CONFIG_ANGULAR,
  MsalService,
  MsalAngularConfiguration
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Configuration } from 'msal';
import { LoginComponent } from './components/login/login.component';
import { ReqDashboardComponent } from './components/req-dashboard/req-dashboard.component';
import { DatePipe } from '@angular/common';
import { ReqApprovalComponent } from './components/req-approval/req-approval.component';
import { ArticleDetailsComponent } from './components/article-details/article-details.component';
import { SearchComponent } from './components/search/search.component';
import { LicenseCreationComponent } from './components/license-creation/license-creation.component';
import { LicenseDashboardComponent } from './components/license-dashboard/license-dashboard.component';
import { LicenseApprovalComponent } from './components/license-approval/license-approval.component';
import { ReqViewComponent } from './components/req-view/req-view.component';
import { LicenseViewComponent } from './components/license-view/license-view.component';
import { NgxMdModule } from 'ngx-md';
import { ReqGithubAccessComponent } from './components/req-github-access/req-github-access.component';
import { ReqAzureDevopsProjectComponent } from './components/req-azure-devops-project/req-azure-devops-project.component';
import { RepositoryAttestationComponent } from './components/repository-attestation/repository-attestation.component';
import { GitRepositoriesComponent } from './components/git-repositories/git-repositories.component';
import { GitAllRepositoriesComponent } from './components/git-all-repositories/git-all-repositories.component';
import { GitOwnerRepositoriesComponent } from './components/git-owner-repositories/git-owner-repositories.component';
import { TeamsComponent } from './components/teams/teams.component';
import { AllTeamsComponent } from './components/all-teams/all-teams.component';
import { OwnerTeamsComponent } from './components/owner-teams/owner-teams.component';
import { TeamAttestationComponent } from './components/team-attestation/team-attestation.component';
import { GithubReqComponent } from './components/github-req/github-req.component';
import { BulkGithubReqComponent } from './components/bulk-github-req/bulk-github-req.component';
import { ProgressComponent } from './components/progress/progress.component';
import { DndDirective } from './components/bulk-github-req/dnd.directive';
import { AzureDevopsLicenseReqComponent } from './components/azure-devops-license-req/azure-devops-license-req.component';

export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read', 'openid', "profile"]]
];

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: environment.APP_CLIENT_ID,
      // -- dev --
      authority: "https://login.microsoftonline.com/"+environment.APP_TENANT_ID,

      // -- Prod --
      //authority: "https://login.microsoftonline.com/548d26ab-8caa-49e1-97c2-a1b1a06cc39c",

      validateAuthority: true,
      // redirectUri: "http://localhost:4200",
      // postLogoutRedirectUri: "http://localhost:4200",
      // redirectUri: "http://localhost:8100",
      // postLogoutRedirectUri: "http://localhost:8100",       
      // postLogoutRedirectUri: "https://ko-devops-test.azureedge.net",
      // redirectUri: "https://ko-devops-test.azureedge.net", 
      
      //Dev url
      postLogoutRedirectUri: "https://devops-test.coke.com/",
      redirectUri: "https://devops-test.coke.com/",   
      // postLogoutRedirectUri: "https://ko-devops.azureedge.net/",
      // redirectUri: "https://ko-devops.azureedge.net/",    
      // postLogoutRedirectUri: "https://devops.coke.com/",
      // redirectUri: "https://devops.coke.com/",     
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  };
}

function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    //popUp: !isIE,
    consentScopes: [
      "user.read",
      "openid",
      "profile",
      environment.APP_LOGIN_CONFIG
    ],
    unprotectedResources: ["https://www.microsoft.com/en-us/"],
    protectedResourceMap,
    extraQueryParameters: {}
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FilterPipe,
    SearchComponent,
    FooterComponent,
    ArticlesComponent,
    CarsoalComponent,
    ServicesComponent,
    DocumentationComponent,
    HomeComponent,
    TrainingComponent,
    ReqEnvironmentComponent, 
    LoginComponent,
    ReqDashboardComponent,
    ReqApprovalComponent,
    ArticleDetailsComponent,
    LicenseCreationComponent,
    LicenseDashboardComponent,
    LicenseApprovalComponent,
    ReqViewComponent,
    LicenseViewComponent,
    ReqGithubAccessComponent,
    ReqAzureDevopsProjectComponent,
    RepositoryAttestationComponent,
    GitRepositoriesComponent,
    GitAllRepositoriesComponent,
    GitOwnerRepositoriesComponent,
    TeamsComponent,
    AllTeamsComponent,
    OwnerTeamsComponent,
    TeamAttestationComponent,
    GithubReqComponent,
    BulkGithubReqComponent,
    ProgressComponent,
    DndDirective,
    AzureDevopsLicenseReqComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
    CarouselModule.forRoot(),
    NgxSpinnerModule,
    MsalModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    ReactiveFormsModule,
    LazyLoadImageModule,
    NgxMdModule.forRoot(),
  ],
  providers: [
    MsalService,
    CoeAuthService,
    CoeAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CoeAuthInterceptor,
      multi: true
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
