<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="m-auto col-md-10 col-lg-10 col-xs-12 col-sm-12">
                <form class="example-form animate__animated animate__zoomIn animate__delay-0.5s" #form="ngForm"
                    (ngSubmit)="processForm(form)">
                    <h5 class="text-left;m-0;">AZURE DEVOPS PROJECT REQUEST</h5>
                    <hr class="mb-4">
                    <div class="row mb-4">
                        <div class="col custom-label">
                            <span>Request to be added to the Coca-Cola organization GitHub</span>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Requested For User
                                Type</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Select Internal if this request for a user with a Coca-Cola email adrress(@coca-cola.com). Otherwise, select external"
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field appearance="outline" style="width: 300px;">
                                <mat-select [(ngModel)]="requestGithubDetail.userType" [formControl]="userType">
                                    <mat-option #userTy *ngFor="let userType of userTypeList" [value]="userType.type"
                                        (onSelectionChange)="setUserType(userTy.value)">
                                        {{userType.type}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="userType.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Requested For
                                Name</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button matTooltip="The name of the person requesting for an Azure DevOps project to be created. The name that you select here will be the Project Administrator of the project. 
                            Project Administrator will have the ability to add team members to the project. If Internal user type is selected, you can search for the person in the list.
                             If External user type is selected, you need to manually type in the name of the person requesting access." aria-label="Purpose">info_outline
                            </mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field appearance="outline" style="width: 300px;"
                                *ngIf="requestGithubDetail.userType == 'Internal'">
                                <input type="email" matInput 
                                    [formControl]="users" [matAutocomplete]="auto" (keyup)="getUsers($event)">
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                    <mat-option *ngFor="let option of userList" [value]="option.userName"
                                        (onSelectionChange)="setEmail(option.userName)">
                                        {{option.userName}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-spinner *ngIf="isUserLoading" [diameter]="30"></mat-spinner>
                            </mat-form-field>
                            <mat-form-field appearance="outline" style="width: 300px;"
                                *ngIf="requestGithubDetail.userType != 'Internal'">
                                <input [(ngModel)]="requestGithubDetail.requestorName" [formControl]="users" matInput>
                                <mat-error *ngIf="users.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Requested For
                                Email</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button matTooltip="The email address of the person requesting for an Azure DevOps project to be created. If Internal user type is selected this field will be auto-populated based on email address of the user in Azure AAD. 
                            If External user type is selected, you need to manually type in the email address of person requesting access." aria-label="Purpose">
                                info_outline
                            </mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field appearance="outline" style="width: 300px;">
                                <input [(ngModel)]="requestGithubDetail.requestorEmail"
                                    [readonly]="requestGithubDetail.userType == 'Internal'" [formControl]="userEmail"
                                    matInput>
                                <mat-error *ngIf="userEmail.invalid && requestGithubDetail.userType == 'External'">
                                    You must enter a valid email</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Role</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Specify the role of the person requesting an Azure DevOps project to be created."
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field appearance="outline" style="width: 300px;">
                                <mat-select [formControl]="userRole" [(ngModel)]="requestGithubDetail.role">
                                    <mat-option *ngFor="let user of userRoleList" [value]="user.role">{{user.role}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="userRole.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Reason</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Specify the reason for requesting an Azure DevOps project to be created."
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field appearance="outline" style="width: 300px;">
                                <input [(ngModel)]="requestGithubDetail.reason" [formControl]="userReason" matInput>
                                <mat-error *ngIf="userReason.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label">
                            <span>Requested For Manager</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button matTooltip="If internal user type is selected, the Requested For Manager field will use the immediate manager of the user selected in the Requested. 
                            For external user type, you can search for the manager in the list." aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field appearance="outline" style="width: 300px;">
                                <input  [formControl]="userManager"
                                    matInput [matAutocomplete]="auto" (keyup)="getUsers($event)"
                                    [readonly]="requestGithubDetail.userType == 'Internal' && isManagerDetails">
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                    <mat-option *ngFor="let option of userList" [value]="option.userName"
                                        (onSelectionChange)="setUserManager(option)">
                                        {{option.userName}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="userManager.invalid && requestGithubDetail.userType == 'External'">
                                    {{getErrorMessage()}}</mat-error>
                                <mat-spinner *ngIf="isUserLoading" [diameter]="30"></mat-spinner>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label">
                            <span>IT Group/Tower/Team</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Specify the IT Group/Tower/Team the requester is a part of."
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field appearance="outline" style="width: 300px;">
                                <mat-select [(ngModel)]="requestGithubDetail.groupName" [formControl]="userITGroups">
                                    <mat-option *ngFor="let user of userITGroupsList" [value]="user.group"
                                        (click)="fetchChampion(user.group)">{{user.group}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="userITGroups.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>DevOps Champion</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button matTooltip="Based on the value you selected in IT Group/Tower/Team you can select one or more
                            DevOps Champions who needs to approve your request." aria-label="Purpose">
                                info_outline
                            </mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field appearance="outline" style="width: 300px;">
                                <mat-chip-list #chipList>
                                    <mat-chip *ngFor="let item of selectedChampions" [selectable]="true"
                                        [removable]="true" (removed)="removeChampion($event)">
                                        {{item.name}}
                                        <mat-icon (click)="removeChampion(item)" matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                    <input #championInput [formControl]="userChampion" [matAutocomplete]="auto1"
                                        [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        [matChipInputAddOnBlur]="true" required (keyup)="filterChampions($event)">
                                </mat-chip-list>
                                <mat-autocomplete #auto1="matAutocomplete"
                                    (optionSelected)="addSelectedChampion($event)">
                                    <mat-option *ngFor="let champion of userChampionList" [value]="champion">
                                        {{champion.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-error class="radio-error" *ngIf="isSubmitted && selectedChampions.length == 0">
                                {{getErrorMessage()}}</mat-error>
                            <mat-error class="radio-error" *ngIf="selectedChampions.length > 3">
                                Maximum 3 administrator allowed </mat-error>


                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Project Name</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button matTooltip="Specify the name of the project your requesting to be created in Azure DevOps. If this request is approved, the Azure DevOps project will be created with the name that you provide. As the project administrator, you can change the name after it has been created." aria-label="Purpose">
                                info_outline
                            </mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field appearance="outline" style="width: 300px;">
                                  <input [(ngModel)]="requestGithubDetail.projectName" [formControl]="userProject" matInput>
                                <mat-error *ngIf="userProject.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Detailed Project
                                Description</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button matTooltip="Provide the detailed description of the Azure DevOps project you're requesting for" aria-label="Purpose">
                                info_outline
                            </mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field appearance="outline" style="width: 300px;">
                                <textarea  [(ngModel)]="requestGithubDetail.projectDescription" [formControl]="userProjectDesc" matTextareaAutosize matAutosizeMinRows="3"
                                    matAutosizeMaxCols="" matInput></textarea>
                                <mat-error *ngIf="userProjectDesc.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col text-center">
                            <button mat-raised-button color="dark" class="back-button"
                                (click)="location.back()">Back</button>&nbsp;&nbsp;&nbsp;
                            <button mat-raised-button color="warn" style="width : 8em;;">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>