export class LicenseDetail {
      PartitionKey: string = "New";
      RowKey: string = "NAN";
      EnvironmentName: string = "";
      ApplicationName: any = [];
      LicenseNumber: number;
      BusinessUnit: string = "";
      EnvironmentOwner: any = [];
      UserName: string = "";
      RequestStatus: string = "";
      Interactedvia: string = "Application";
}
    
