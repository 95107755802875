<div class="container">
  <!-- <div class="row">
      <div class="widget col p-0">
        <h4>POWERCOE</h4>       
      </div>
    </div> -->
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
      <h6 class="title" style="color: #b9b5b5;">About us</h6>
      <p class="m-0">Site Reliability Engineering (SRE) leads the overall<br>
        strategy and adoption of DevSecOps practices <br>
        and tools within Coca-Cola.    
      </p>
      <!-- <a class="m-0 button small text-left p-0" routerLink="/aboutus" style="font-size:0.8rem;color: #b9b5b5;">Meet the
        team</a> -->
    </div>
    <!-- end widget -->
    <!-- <div class="widget col-lg-4 col-md-4 col-sm-12">
        <div class="title">Recent Posts</div>
        <ul class="recent_posts">
          <li>
            <a href="home1.html#">
              <img src="img/recent_post_01.png" alt="" />Our New Dashboard Is Here</a>
            <a class="readmore" href="#">read more</a>
          </li>
          <li>
            <a href="home1.html#">
              <img src="img/recent_post_02.png" alt="" />Design Is In The Air</a>
            <a class="readmore" href="#">read more</a>
          </li>
        </ul>
      
      </div> -->
    <!-- end widget -->
    <!-- <div class="widget col-lg-4 col-md-4 col-sm-12">
        <div class="title">Get In Touch</div>
        <ul class="contact_details">
          <li><i class="fa fa-envelope-o"></i> info@yoursite.com</li>
          <li><i class="fa fa-phone-square"></i> +34 5565 6555</li>
          <li><i class="fa fa-home"></i> Some Fine Address, 887, Madrid, Spain.</li>
          <li><a href="#"><i class="fa fa-map-marker"></i> View large map</a></li>
        </ul>
        
      </div> -->
    <!-- end widget -->
    <div class="widget col-lg-3 col-md-3 col-sm-3 col-xs-3 mb-2">
      <h6 class="title" style="color: #b9b5b5;">Follow us</h6>
      <ul class="followus mb-0">
        <li class="wow animate__animated animate__zoomIn animate__delay-0.5s"><a target="_blank"
            href="https://www.yammer.com/coca-cola.com/#/threads/inGroup?type=in_group&feedId=15670878208"><img
              class="yammer" alt="" src="../../../assets/img/yammer.png"></a></li>
        <li class="wow animate__animated animate__zoomIn animate__delay-0.5s"><a target="_blank"
            href="https://web.microsoftstream.com/channel/51ef0cb1-060d-439b-a884-4e1e1b96c4ca"><img class="stream"
              alt="" src="../../../assets/img/stream.svg.png"></a></li>
        <!-- <li><a href="#"><img alt="" src="../../../assets/img/facebook.png"></a></li>
          <li><a href="#"><img alt="" src="../../../assets/img/twitter.png"></a></li>        -->
      </ul>

    </div>
    <div class="widget col-lg-3 col-md-3 col-sm-3 col-xs-3 mb-2">
      <h6 class="title" style="color: #b9b5b5;">Dark mode</h6>
      <label class='toggle-label'>
        <input type='checkbox' [(ngModel)]="isDarkChecked" (change)="changeTheme($event)" />
        <span class='back'>
          <span style="cursor: pointer;" class='toggle'></span>
          <span style="cursor: pointer;" class='label on'>ON</span>
          <span style="cursor: pointer;" class='label off'>OFF</span>
        </span>
      </label>
    </div>
    <!-- <div class="widget col-lg-4 col-md-4 col-sm-12">
        <h6 class="title">Theme</h6>
        <ul class="followus">
          <mat-radio-group aria-label="Select an option">
            <li><mat-radio-button (click)="changeTheme('style')" value="style" class="text-white">Default</mat-radio-button></li>
            <li> <mat-radio-button (click)="changeTheme('darkstyle')"  value="darkstyle" class="text-white">Dark</mat-radio-button></li>
          </mat-radio-group>       
        </ul>
      
      </div> -->
    <!-- end widget -->
  </div>
</div>
<!-- end container -->

<div class="copyrights">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center columns footer-left">
        <p class="wow animate__animated animate__zoomIn animate__delay-0.5s" style="color: #b9b5b5;">Copyright 2020 - SRE</p>
      </div>
      <!-- end widget -->
    </div>
    <!-- end large-6 -->
  </div>
  <!-- end container -->
</div>
<!-- end copyrights -->