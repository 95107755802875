<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col text-left">
                <h5>FEATURED ARTICLES </h5>
            </div>
            <div class="col text-right">
                <a class="text-right featured-artocle-title" routerLink="/articles" style="color:#000000;margin-left:-2px;">Browse all
                    articles<mat-icon style="vertical-align: top;;
            ">keyboard_arrow_right</mat-icon></a>
            </div>
        </div>
        <hr>
        <div class="row" *ngIf="spinner">
            <div class="col">
                <div class="spinnerDivHeight">
                    <ngx-spinner [name]="spinnerName" bdColor="transparent" size="default" color="red" type="timer"
                        [fullScreen]="false">
                        <p style="color: #000;margin-top:50px"> Please wait ... </p>
                    </ngx-spinner>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div id="carouselDesktop" class="carousel slide mat-parent" data-ride="carousel">
                    <div class="carousel-inner">
                        <div *ngFor="let slidelist of slide; let i = index"
                            class="{{i===0 ? 'carousel-item active': 'carousel-item'}}">
                            <div class="row">
                                <div class="col-md-3" *ngFor="let post of slidelist | appFilter: searchText: component">
                                    <mat-card
                                        class="example-card wow animate__animated animate__zoomIn animate__delay-0.5s">
                                        <div>
                                            <img *ngIf="post.better_featured_image" mat-card-image
                                                class="article-card-image"
                                                src="{{post.better_featured_image.source_url}}"
                                                alt="Photo of a Shiba Inu" routerLink="/articles-details/{{post.id}}">
                                            <img *ngIf="!post.better_featured_image" mat-card-image
                                                class="article-card-image"
                                                src="../../../assets/img/articles/articles.jpg"
                                                alt="Photo of a Shiba Inu" routerLink="/articles-details/{{post.id}}">
                                            <div mat-card-avatar class="profile-header-article-avator"></div>
                                        </div>
                                        <mat-card-content>
                                            <div class="card-body">
                                                <h4 class="card-title ellipsis"><a
                                                        routerLink="/articles-details/{{post.id}}">{{post.title.rendered ? post.title.rendered : "Loading ..."}}</a>
                                                </h4>
                                                <p>{{post.date | date:'longDate'}}</p>
                                                <div class="excerpt-content" [innerHtml]="post.excerpt.rendered"
                                                    *ngIf="post && post.excerpt">
                                                </div>
                                                <button mat-stroked-button color="warn"
                                                    routerLink="/articles-details/{{post.id}}">Read More...</button>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev" *ngIf="slide.length" href="#carouselDesktop" role="button"
                        data-slide="prev" style="color:red">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" *ngIf="slide.length" href="#carouselDesktop" role="button"
                        data-slide="next" style="color:red">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>


                <div id="carouselMobile" class="carousel slide mat-parent-small" data-ride="carousel">
                    <div class="carousel-inner">
                        <div *ngFor="let post of latestPost; let i = index"
                            class="{{i===0 ? 'carousel-item active': 'carousel-item'}}">
                            <mat-card class="example-card wow animate__animated animate__zoomIn animate__delay-0.5s">
                                <div>
                                    <img *ngIf="post.better_featured_image" mat-card-image class="article-card-image"
                                        src="{{post.better_featured_image.source_url}}" alt="Photo of a Shiba Inu"
                                        routerLink="/articles-details/{{post.id}}">
                                    <img *ngIf="!post.better_featured_image" mat-card-image class="article-card-image"
                                        src="../../../assets/img/articles/articles.jpg" alt="Photo of a Shiba Inu"
                                        routerLink="/articles-details/{{post.id}}">
                                    <div mat-card-avatar class="profile-header-article-avator"></div>
                                </div>
                                <mat-card-content>
                                    <div class="card-body">
                                        <h4 class="card-title ellipsis"><a
                                                routerLink="/articles-details/{{post.id}}">{{post.title.rendered ? post.title.rendered : "Loading ..."}}</a>
                                        </h4>
                                        <p>{{post.date | date:'longDate'}}</p>
                                        <div class="excerpt-content" [innerHtml]="post.excerpt.rendered"
                                            *ngIf="post && post.excerpt"></div>
                                        <button mat-stroked-button color="warn"
                                            routerLink="/articles-details/{{post.id}}">Read
                                            More...</button>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <a class="carousel-control-prev" *ngIf="latestPost.length" href="#carouselMobile" role="button"
                        data-slide="prev" style="color:red">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" *ngIf="latestPost.length" href="#carouselMobile" role="button"
                        data-slide="next" style="color:red">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="row mt-4" *ngIf="noRecords">
            <div class="col text-center mx-auto">
                <div class="error-message-template">
                    No Records
                </div>
            </div>
        </div>
        <div class="row mt-4" *ngIf="error">
            <div class="col text-center mx-auto">
                <div class="error-message-template">
                    {{errorMsg}}
                </div>
            </div>
        </div>
    </div>
</section>