import { Component, OnInit, Input, Renderer2, Inject, OnDestroy } from '@angular/core';
// import { DOCUMENT } from '@angular/common';
import * as d3 from 'd3';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() deviceXs: boolean;

  scriptTag: any;
  data: any;

  // constructor(
  //   private renderer2: Renderer2,
  //   @Inject(DOCUMENT) private _document: Document
  // ) {
  //   this.scriptTag = this.renderer2.createElement('script');     
  //   this.scriptTag.type = 'text/javascript';
  //   this.scriptTag.src = '../../../assets/d3script.js';
  //   this.scriptTag.text = ``;
  //   this.renderer2.appendChild(this._document.body, this.scriptTag);
  //  }

  // ngOnInit() {

  // }




  // ngAfterViewInit() {
  //   this.scriptTag = this.renderer2.createElement('script');     
  //   this.scriptTag.type = 'text/javascript';
  //   this.scriptTag.src = '../../../assets/d3script.js';
  //   this.scriptTag.text = ``;
  //   this.renderer2.appendChild(this._document.body, this.scriptTag);
  // }


  // ngOnDestroy(): void {

  //   this.scriptTag.parentNode.removeChild(this.scriptTag);
  // }

  // columnChart;
  // donutChart;

  // initColumn() {
  //   const column = new Chart({
  //     chart: {
  //       type: ''
  //     },
  //     title: {
  //       text: ''
  //     },
  //     credits: {
  //       enabled: false
  //     },

  //     // series: [{
  //     //   name: 'Line 1',
  //     //   data: [30, 30, 30, 30, 30, 30],
  //     //   type: undefined
  //     // }]
  //   });
  //   column.addPoint(12);
  //   this.columnChart = column;
  //   column.ref$.subscribe(console.log);
  // }
  // initDonut() {
  //   const donut = new Chart({
  //     chart: {        
  //       plotBackgroundColor: '#000000',        
  //       plotBorderWidth: 0,
  //       plotShadow: true
  //     },
  //     // title: {
  //     //   text: '<strong>1137<br>streams</strong>',
  //     //   align: 'center',
  //     //   verticalAlign: 'middle',
  //     //   y: 0
  //     // },
  //     // tooltip: {
  //     //   pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
  //     // },
  //     credits: {
  //       enabled: false
  //     },
  //     plotOptions: {
  //       pie: {
  //         allowPointSelect: false,
  //         cursor: 'pointer',
  //         dataLabels: {
  //           enabled: true,
  //           distance: -50,
  //           style: {
  //             fontWeight: 'bold',
  //             color: 'white'
  //           }
  //         },
  //         // startAngle: -90,
  //         // endAngle: -180,
  //         center: ['50%', '50%'],
  //         size: '100%',
  //         showInLegend: false
  //       }
  //     },
  //     series: [
  //       {
  //         name: 'Devops',
  //         data: [
  //           {
  //             name: 'OPERATE',
  //             y: 10
  //           },
  //           {
  //             name: 'PLAN',
  //             y: 10,
  //           }, {
  //             name: 'CREATE',
  //             y: 10,
  //           }, {
  //             name: 'BUILD',
  //             y: 10
  //           }, {
  //             name: 'TEST',
  //             y: 10
  //           },{
  //             name: 'DEPLOY',
  //             y: 10
  //           }],
  //         type: 'pie',
  //         innerSize: '30%',
  //       }]
  //   });
  //   this.donutChart = donut;
  // }

  // ngOnInit() {
  //   this.initColumn();
  //   this.initDonut();
  // }

 
  width = 500;
  height = 500
  margin = 40    
  growth = 20;
  innerRadius = 100;
  regularFontSize = 17;
  largeFontSize = 19;
  animationDuration = 500;
  radius;
  svg;
  pie;
  data_ready;





  constructor() { }

  ngOnInit() {

    this.draw();   

  }

  draw() {
    
    if(this.deviceXs == true) {
      this.width = 300;
      this.height = 300;
      this.margin = 10; 
      this.growth = 10;
      this.innerRadius = 50;
      this.regularFontSize = 15;
      this.largeFontSize = 15;
      this.animationDuration = 500;
    } else {
      this.width = 500;
      this.height = 500;
      this.margin = 40;    
      this.growth = 20;
      this.innerRadius = 100;
      this.regularFontSize = 17;
      this.largeFontSize = 19;
      this.animationDuration = 500;
    }

    this.radius = Math.min(this.width, this.height) / 2 - this.margin;


    this.svg = d3.select('#my_dataviz')
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr("transform", "translate(" + this.width / 2 + "," + this.height / 2 + ")");

    this.data = { plan: 1, create: 1, build: 1, test: 1, deploy: 1, operate: 1 };

    let color = d3.scaleOrdinal().domain(Object.keys(this.data)).range(["#ea3223", "#ffcc33", "#99cc66", "#669966", "#3399ff", "#663399"]);


    this.pie = d3.pie().value(function (d:any) { 
      return d.value;
     }).padAngle(.04);
    this.data_ready = this.pie(d3.entries(this.data));

    var arcGenerator = d3.arc()
      .innerRadius(this.innerRadius) // This is the size of the donut hole
      .outerRadius(this.radius);

    var arcOver = d3.arc().innerRadius(this.innerRadius + this.growth).outerRadius(this.radius + this.growth);   

    this.pie = this.svg
      .selectAll('pie')
      .data(this.data_ready)
      .enter()
      .append('path')
      .attr('class', 'pie-slice')
      .attr('d', arcGenerator
      )
      .attr("id", function (d) {       
        return d.data.key
       })
      .on("mouseover", function (d) {
        d3.select(this).transition().duration(this.animationDuration).attr("d", arcOver);
        d3.select("#" + d.data.key + "-text")
          .transition()
          .duration(this.animationDuration)
          .attr("transform", function (d: any) {
             return "translate(" + arcOver.centroid(d) + ")";
             })
          .style("font-weight", "bold")
          .style("font-size", this.largeFontSize)
      })
      .on("mouseleave", function (d) {
        d3.select(this).transition().duration(this.animationDuration).attr("d", arcGenerator);
        d3.select("#" + d.data.key + "-text")
          .transition()
          .duration(this.animationDuration)
          .attr("transform", function (d: any) { 
            return "translate(" + arcGenerator.centroid(d) + ")";
           })
          .style("font-weight", "normal")
          .style("font-size", this.regularFontSize)
      })
      .on("click", this.handlePieClick)
      .attr('fill', function (d) { 
        return (color(d.data.key))
      })
      .attr("stroke", "black")
      .attr("padding", "10px")
      .style("stroke-this.width", "2px")
      .style("opacity", 1);


    this.svg
      .selectAll('pie')
      .data(this.data_ready)
      .enter()
      .append('text')
      .attr("id", function (d) { 
        return d.data.key + "-text"
       })
      .text(function (d) { 
        return d.data.key.toUpperCase( ) 
      })
      .style("fill", "white")
      .attr("transform", function (d) { 
        return "translate(" + arcGenerator.centroid(d) + ")"; 
      })
      .style("text-anchor", "middle")
      .style("font-size", 17)
      .style("font-family", "Arial");
  }

  // handlePieMouseOver(d, i) {
  //       console.log(d, i);
  //       d3.select(this)
  //           .attr("d", arcOver);
  //   }

  // function handlePieClick(d){
  // var datakey = d.data.key;
  // $('html, body').animate(
  // {
  // scrollTop: $("#"+datakey+"-section").offset().top,
  // },
  // 500,
  // 'linear'
  // );
  // } 

  handlePieClick(d) {
    var datakey = d.data.key;
    // location.href = 'https://wiki.coke.com/confluence/display/DEV/IT+Value+Chain'+"#"+datakey+"-section"
  }


}
