<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col">
                <form class="example-form  animate__animated animate__zoomIn animate__delay-0.5s">
                    <h4 style="text-align:left;margin:0px;">VIEW LICENSE REQUEST</h4>
                    <hr>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Environment</span>

                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label>{{licenseDetail.environmentName}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Applications</span>

                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">


                            <mat-chip-list #chipListCon>
                                <mat-chip *ngFor="let item of selectedApps" [selectable]="true" [removable]="true">
                                    {{item}}
                                </mat-chip>
                            </mat-chip-list>


                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Number of license</span>

                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-label>{{licenseDetail.licenseNumber}}</mat-label>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Business Unit /
                                Function</span>

                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-label>{{licenseDetail.businessUnit}}</mat-label>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Environment Owner</span>

                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label *ngFor="let owner of licenseDetail.environmentOwner">{{owner.userName}},
                            </mat-label>
                            <mat-label *ngIf="licenseDetail.environmentOwner == null"> NA </mat-label>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col text-center">
                            <button mat-raised-button color="dark" class="back-button"
                                (click)="location.back()">Back</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>