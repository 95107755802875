import { Component, OnInit, ViewChild } from '@angular/core';
import { ApibundleService } from '../../services/apibundle.service';
import { Router } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from '../../services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-all-teams',
  templateUrl: './all-teams.component.html',
  styleUrls: ['./all-teams.component.css']
})
export class AllTeamsComponent implements OnInit {
  kbId: string;
  err;
  public allTeams: any = [];
  username: string = "user";
  norecord: boolean = false;
  public headerToolBar: boolean = false;
  public columns: any;
  displayedColumns: string[] = ['requestorName'];
  limit: number = 5;
  requestDataSourceMobile: any;
  isFilterData: boolean = false;
  @ViewChild(MatPaginator, { static: false }) paginators: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  spinner: boolean = false;
  spinnerName = "allTeam";

  constructor(
    private apibundleService: ApibundleService,
    private commonService: CommonService,
    private authService: MsalService,
    public router: Router,
    private broadcastService: BroadcastService,
    public location: Location
  ) {
    this.broadcastService.subscribe("msal:loginSuccess", payload => {
      const user = this.authService.getAccount().name.split(" ");
      this.username = user[0];
      localStorage.setItem('userName', this.authService.getAccount().userName);
    });
  }

  ngOnInit() {
    this.getAllTeams();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.requestDataSourceMobile.filter = filterValue;
    if ((this.requestDataSourceMobile.filteredData.length == 0) && filterValue.length > 0)
      this.isFilterData = true;
    else
      this.isFilterData = false;
  }

  doRefresh(event) {
    console.log('Begin async operation');
    this.getAllTeams();
    setTimeout(() => {
      console.log('Async operation has ended');
      event.target.complete();
    }, 2000);
  }

  viewRequest(requestDetail) {
      this.router.navigate(['/services/request/view/' + requestDetail.requestType + '/' + requestDetail.id]);
  }
  
  getAllTeams() {
    this.norecord = false;
    this.spinner = true;
    this.apibundleService.showSpinner('allTeam');
    this.apibundleService.getAllTeams().subscribe(
      res => {
        this.allTeams = res;
        this.formatTable(); 
      },
      err => {
        this.err = err;
        console.log(err);
      });
  }

  formatTable() {
    setTimeout(() => {
      this.requestDataSourceMobile = new MatTableDataSource<any>(this.allTeams);
      this.requestDataSourceMobile.paginator = this.paginators;
      this.requestDataSourceMobile.sort = this.sort;
      if (this.requestDataSourceMobile.paginator)
        this.requestDataSourceMobile.paginator._intl.itemsPerPageLabel = 'Teams per page';
      this.apibundleService.hideSpinner('allTeam');
      this.spinner = false;
    }, 2000);
    if (this.allTeams.length === 0) {
      this.norecord = true;
    } else {
      this.norecord = false;
    }
  }

  removeDataFromTable() {
    this.requestDataSourceMobile = new MatTableDataSource<any>([]);
    this.requestDataSourceMobile.paginator = this.paginators;
    this.requestDataSourceMobile.sort = this.sort;
  }

  redirectToTeamDetail(teamId) {
    this.router.navigateByUrl('services/teamattestation/allteams/' + teamId); 
  }
  

}
