import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-git-repositories',
  templateUrl: './git-repositories.component.html',
  styleUrls: ['./git-repositories.component.css']
})
export class GitRepositoriesComponent implements OnInit {
 
  public isOwnerTabSelected: boolean = false;
  selectedTab: any = 0;
  constructor() {
  }

  ngOnInit() {
    if(window.localStorage.getItem('code') && window.localStorage.getItem('code') != '') {
      this.selectedTab = 1;
      this.isOwnerTabSelected = true;
    }
  }

  enableOwnerRepoTab(event) {
    if(event.index == 1)
      this.isOwnerTabSelected = true;
    else    
      this.isOwnerTabSelected = false;
  }

}
