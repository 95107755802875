<div class="container">
    <div class="row">
      <div class="col mt-4">
        <div class="mat-tab-container animate__animated animate__zoomIn animate__delay-0.5s" >
          <mat-table [dataSource]="requestDataSourceMobile" matSort>

            <ng-container matColumnDef="requestorName">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                style="flex-direction: unset;justify-content: left;padding-left:15px;"> ALL TEAMS </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div class="col-md-6">
                  <div class="row mt-4">
                    <label>
                      <h5 (click)="redirectToTeamDetail(element.id)"> {{element.name}}</h5>
                    </label>
                  </div>
                  <div class="row mt-2 mb-4">   
                      <label class="material-icons" style="font-size: 20px">
                        person
                      </label>
                      <label class="ml-1"> {{element.owner != null ? element.owner : 'NAN'}} </label>
                    <div class="ml-4">
                      <label class="ml-1" style="position:absolute"> Certified on {{element.certification_Date | date :'medium' }}</label>
                    </div>
                  </div>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"></mat-row>
          </mat-table>
          <div class="row" *ngIf="spinner">
            <div class="col">
              <div class="spinnerDivHeight">
                <ngx-spinner [name]="spinnerName" bdColor="transparent" size="default" color="red" type="timer"
                  [fullScreen]="false">
                  <p style="color: #000;margin-top:50px"> Please wait ... </p>
                </ngx-spinner>
              </div>
            </div>
          </div>
          <div class="norecord mt-4" *ngIf="norecord">
            <mat-label class="message">You don't have any Github Repositories.</mat-label>
          </div>
          <div *ngIf="isFilterData" class="mt-4">
            <mat-label class="message">Your search <b>{{this.requestDataSourceMobile.filter}}</b> - did not match any
              Requests
            </mat-label>
          </div>
          <mat-paginator *ngIf="!norecord" #paginatorMobile [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"
            [showFirstLastButtons]="true">
          </mat-paginator>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!spinner">
      <div class="col text-center">
        <div class="norecord">
          <button mat-raised-button color="dark" style="width : 8em;;background-color: #333;color:#fff;"
            (click)="location.back()">Back</button>&nbsp;&nbsp;&nbsp;

        </div>
      </div>
    </div>
  </div>