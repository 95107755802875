import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArticlesComponent } from './components/articles/articles.component';
import { ServicesComponent } from './components/services/services.component';
import { HomeComponent } from './components/home/home.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { TrainingComponent } from './components/training/training.component';
import { ReqEnvironmentComponent } from './components/req-environment/req-environment.component';
import { MsalGuard } from '@azure/msal-angular';
import { CoeAuthGuard } from './guards/coe-Auth.guard'
import { ReqDashboardComponent } from './components/req-dashboard/req-dashboard.component';
import { ReqApprovalComponent } from './components/req-approval/req-approval.component';
import { ArticleDetailsComponent } from './components/article-details/article-details.component';
import { LicenseCreationComponent } from './components/license-creation/license-creation.component';
import { LicenseDashboardComponent } from './components/license-dashboard/license-dashboard.component';
import { LicenseApprovalComponent } from './components/license-approval/license-approval.component';
import { ReqViewComponent } from './components/req-view/req-view.component';
import { LicenseViewComponent } from './components/license-view/license-view.component';
import { ReqGithubAccessComponent } from './components/req-github-access/req-github-access.component';
import { ReqAzureDevopsProjectComponent } from './components/req-azure-devops-project/req-azure-devops-project.component';
import { RepositoryAttestationComponent } from './components/repository-attestation/repository-attestation.component';
import { GitRepositoriesComponent } from './components/git-repositories/git-repositories.component';
import { TeamsComponent } from './components/teams/teams.component';
import { TeamAttestationComponent } from './components/team-attestation/team-attestation.component';
import { GithubReqComponent } from './components/github-req/github-req.component';
import { AzureDevopsLicenseReqComponent } from './components/azure-devops-license-req/azure-devops-license-req.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [CoeAuthGuard] },
  { path: 'services', component: ServicesComponent, canActivate: [CoeAuthGuard] },
  { path: 'documentation', component: DocumentationComponent, canActivate: [CoeAuthGuard] },
  { path: 'documentation/:docs', component: DocumentationComponent, canActivate: [CoeAuthGuard] },
  { path: 'training', component: TrainingComponent, canActivate: [CoeAuthGuard] },
  { path: 'articles', component: ArticlesComponent, canActivate: [CoeAuthGuard]},
  { path: 'articles-details/:id', component: ArticleDetailsComponent, canActivate: [CoeAuthGuard]},
  { path: 'services/myrequests', component: ReqDashboardComponent, canActivate: [CoeAuthGuard] },
  { path: 'services/approverequest', component: ReqApprovalComponent, canActivate: [CoeAuthGuard] },
  { path: 'services/github/requestgithubaccess', component: GithubReqComponent, canActivate: [CoeAuthGuard] },
  { path: 'services/github/requestazuredevopsproject', component: ReqAzureDevopsProjectComponent, canActivate: [CoeAuthGuard] },
  { path: 'services/request/view/:reqType/:reqId', component: ReqViewComponent, canActivate: [CoeAuthGuard] },
  // { path: 'userlist', component: ReqEnvironmentComponent, canActivate: [CoeAuthGuard] },
  // { path: 'services/license/createrequest', component: LicenseCreationComponent, canActivate: [CoeAuthGuard] },
  // { path: 'services/license/dashboard', component: LicenseDashboardComponent, canActivate: [CoeAuthGuard] },
  // { path: 'services/license/approvelicense', component: LicenseApprovalComponent, canActivate: [CoeAuthGuard] },
  // { path: 'services/license/view/:reqId', component: LicenseViewComponent, canActivate: [CoeAuthGuard] },
  { path: 'services/githubrepositories', component: GitRepositoriesComponent, canActivate:[CoeAuthGuard]}, 
  { path: 'services/teams', component: TeamsComponent, canActivate:[CoeAuthGuard]}, 
  { path: 'services/repoattestation/:repoPage/:repoId', component: RepositoryAttestationComponent, canActivate:[CoeAuthGuard]}, 
  { path: 'services/teamattestation/:teamPage/:teamId', component: TeamAttestationComponent, canActivate:[CoeAuthGuard]}, 
  { path: 'services/licenserequest', component: AzureDevopsLicenseReqComponent, canActivate:[CoeAuthGuard]}, 
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
