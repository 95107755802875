import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit {
  searchText = '';
  component = '';
  
  constructor(
    private messageService: MessageService
  ) {
    this.messageService.getMessage().subscribe(message => {
      if (message == this.messageService.searchText) {
        this.searchText = message[0].searchText;
        this.component = message[0].component;
      }
    });  
  }

  ngOnInit(): void {
  }

  messagesLern = [
    {     
      subject: "Microsoft Learn ",
      content: "offers various training modules and learning paths for DevOps engineers.",
      img: "mc.png",
      link: "https://docs.microsoft.com/en-us/learn/roles/devops-engineer",
      readMore: "Get Started"
    },
    {     
      subject: "GitHub Learning Lab ",
      content: "is a fun and interactove way to learn Github.It is a hands-on learning atool created by the GitHub community and taught by the friendly Learning Lab bot. You will be working on real projects directly in GitHub with guidance and help from the Learning Lab bot.",
      img: "learninghub.PNG",
      link: " https://lab.github.com/",
      readMore: "Learn More"
    },
    {     
      subject: "Codecademy PRO ",
      content: "is our online learning tool for people to learn how to code.Codecademy's hands-on approach to learning is good for beginners and experienced developes alike. It offers a variety of subjects(Machine Learning, Data Science, Web Development, etc) and the most popular progtamming languages(Python, JavaScript, Ruby, Go, Swift, Kotlin, etc. ) We have very limited user licenses for Codecademy and the waiting list is quite long",
      img: "codecademy.jpg",
      link: "mailto:brikwon@coca-cola.com;jgaraygay@coca-cola.com;anpope@coca-cola.com?subject=Codecademy Access Request&body=Hello%2C%20My%20name%20is%20%3Cprovide%20your%20full%20name%3E.%20I%20am%20a%20%3Cspecify%20your%20role%20scrum%20master%7Cproduct%20owner%7Cdeveloper%7Cadministrator%7Ctester%7Cetc%3E%20as%20part%20of%20%3Cspecify%20the%20organization%7Ctower%7Cteam%20that%20you're%20a%20%5Cn%20part%20of%3E.%20I%20would%20like%20request%20access%20to%20Codecademy%20because%20%3Cprovide%20your%20reason%20why%20you%20need%20access%20to%20Codecademy%3E.%0D%0AThanks%2C%0D%0A%3Cprovide%20your%20name%20of%20signature%3E",
      readMore: "Request Access"
    },
    {     
      subject: "LinkedIn Learning ",
      content: "is available for free for all Coca-Cola employees. It offers self-paced online courses covering a wide variety of business, creative, and technology topic areas including generic DevOps topics, Azure DevOps and GitHub.",
      img: "linked.png",
      link: "https://www.linkedin.com/learning/search?categoryIds=573028&keywords=devops&u=2056868",
      readMore: "Get Started",     
    }
  ]
  resourceList = [
    {     
      item: "GitHub Docs",
      link: "https://docs.github.com/en"
    },
    {     
      item: "Microsoft Docs",
      link: "https://docs.microsoft.com/en-us/azure/devops/?view=azure-devops"
    },
  ]
  messagesList = [
    {
      subject: 'Power Apps Component Framework deep-drive',
      date: '25/07/2020',
      duration: '4 hours',
      instructor: 'Lokeshwar vangala'
    },
    {
      subject: 'Power Apps Component Framework deep-drive',
      date: '25/07/2020',
      duration: '4 hours',
      instructor: 'Lokeshwar vangala'
    },
    {
      subject: 'Power Apps Component Framework deep-drive',
      date: '25/07/2020',
      duration: '4 hours',
      instructor: 'Lokeshwar vangala'
    },
    {
      subject: 'Power Apps Component Framework deep-drive',
      date: '25/07/2020',
      duration: '4 hours',
      instructor: 'Lokeshwar vangala'
    },
    {
      subject: 'Power Apps Component Framework deep-drive',
      date: '25/07/2020',
      duration: '4 hours',
      instructor: 'Lokeshwar vangala'
    },
  ]

}
