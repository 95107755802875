import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { TooltipPosition } from '@angular/material/tooltip';
import { Component, OnInit, ViewChild, Self, Optional } from '@angular/core';
import { ApibundleService } from '../../services/apibundle.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { RequestGithubDetail } from '../../interfaces/RequestGithubDetail.model';
import { CommonService } from '../../services/common.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { startWith, map } from 'rxjs/operators';
import { EnvName } from '../../interfaces/envName.model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-req-github-access',
  templateUrl: './req-github-access.component.html',
  styleUrls: ['./req-github-access.component.css']
})
export class ReqGithubAccessComponent implements OnInit {
  ngForm: FormGroup;
  loading: any;
  reqFromUrl: string = "";
  public title = "";
  public disabled;
  public btnTxt: string = "";
  norecord: boolean = false;
  approvedList;
  requestGithubDetail = new RequestGithubDetail();
  err: any;
  isUserLoading: boolean  = false;
  selectedChampions: any = [];
  @ViewChild('championInput', {static: false}) championInput;
  isSubmitted: boolean = false;
  isManagerDetails: boolean = true;
  
  separatorKeysCodes: number[] = [ENTER, COMMA];
  /*--------  FORM CONTROL - NEED TO CHANGE IN FUTURE-------- */

  userType = new FormControl('', [Validators.required]);
  users = new FormControl('', [Validators.required]);
  userRole = new FormControl('', [Validators.required]);
  userEmail = new FormControl('', [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
  userManager = new FormControl('', [Validators.required]);
  userReason = new FormControl('', [Validators.required]);
  userITGroups = new FormControl('', [Validators.required]);
  userChampion = new FormControl('', [Validators.required]);

  /*--------  END OF FORM CONTROL -------- */

  userTypeList: any = [];
  userList: any = [];
  userRoleList: any = [];
  userITGroupsList: any = [];
  userChampionList: any = [];
  currentValue: string = null;



  constructor(
    private apibundleService: ApibundleService,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: MsalService,
    private broadcastService: BroadcastService,
    private commonService: CommonService,
    public location: Location
  ) {
    this.userTypeList = [
      { id: 1, type: 'Internal' },
      { id: 2, type: 'External' }
    ]
    this.userRoleList = [
      { id: 1, role: 'Developer' },
      { id: 2, role: 'Architect' },
      { id: 3, role: 'Scrum Master' },
      { id: 4, role: 'Product Owner' },
      { id: 5, role: 'Manager' },
      { id: 6, role: 'Director' },
      { id: 7, role: 'Other' }
    ];

    this.fetchITGroups();
  }


  ngOnInit() {
    this.initializeValues();
  }


  initializeValues() {
    this.requestGithubDetail = new RequestGithubDetail();
  }

  async processForm(formData) {
    if(formData.submitted) {
      this.isSubmitted = true;
      this.requestGithubDetail.devOpsChampion = this.selectedChampions;
      this.requestGithubDetail.userName = this.authService.getAccount().name;
      this.requestGithubDetail.userEmail = this.authService.getAccount().userName;
    }
    if (this.isValid()) {
      this.apibundleService.createGithubRequest(this.requestGithubDetail).subscribe(
        res => {
          this.commonService.openSnackBar("New GitHub access has been created successfully", "OK");
          this.router.navigate(['/services']);
        },
        err => {
          this.err = err;
          console.log(err);
        });
    }
  }

  isValid() {
    for (var key in this.requestGithubDetail) {
      if (this.requestGithubDetail[key] == null || this.requestGithubDetail[key] == '' || this.requestGithubDetail[key].length == 0) {
        if(this.requestGithubDetail.managerName == '') {
          this.userManager.patchValue('');
        } else {
          this.userManager.patchValue(this.requestGithubDetail.managerName);
        }
        if(this.requestGithubDetail.requestorName == '') {
          this.users.patchValue('');
        } else {
          this.users.patchValue(this.requestGithubDetail.requestorName);
        }
        
        return false;
      }
    }
    if(this.selectedChampions.length != 0 && this.selectedChampions.length <= 3 && this.requestGithubDetail.requestorEmail.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
      return true;
    } else {
      return false;
    }
  }

  getErrorMessage() {
    return 'You must enter a value';
  }

  async getUsers(event) {
    if (event.target.value.length > 2) {
      this.isUserLoading = true;
      this.userList = [];
      var searchData = event.target.value;
      this.apibundleService.getEnvAdmins(searchData).subscribe(
        res => {
          this.userList = res;
          this.isUserLoading = false;
        },
        err => {
          this.err = err;
          this.isUserLoading = false;
          console.log(err);
        });
    }
  }

  setUserType(id) {
    this.requestGithubDetail.userType = id;
    this.requestGithubDetail.managerName = '';
    this.requestGithubDetail.requestorEmail = '';
    this.userEmail.setValue('');
    this.userManager.setValue('');
    this.requestGithubDetail.requestorName = '';
    this.users.setValue('');
    this.requestGithubDetail.managerEmail = '';
  }

  setUserManager(manager) {
    this.requestGithubDetail.managerName = manager.userName;
    this.requestGithubDetail.managerEmail = manager.userEmail;
  }

  setEmail(username) {
    let email = this.userList.filter((user) => {
      if (user.userName === username) {
        return user;
      }
    });
    this.userEmail = new FormControl(email[0].userEmail, [Validators.required]);
    this.fetchManagerDetails(email[0].userEmail);
  }

  fetchManagerDetails(userEmail) {
    this.isManagerDetails = true;
    this.apibundleService.getManager(userEmail).subscribe(
      res => {
        this.userManager = new FormControl(res.displayName, [Validators.required]);
        this.requestGithubDetail.managerName = res.displayName;
        this.requestGithubDetail.managerEmail = res.mail;
        this.requestGithubDetail.requestorEmail = userEmail;
        if(res.displayName == '') {
          this.isManagerDetails = false;
        }
      },
      err => {
        this.err = err;
        console.log(err);
      });

  }

  fetchITGroups() {
    this.apibundleService.getGroups().subscribe(
      res => {
        this.userITGroupsList = res;
      },
      err => {
        this.err = err;
        console.log(err);
      });
  }

  fetchChampions(itgroup) {
    this.userChampionList = [];
    this.apibundleService.getChampion(itgroup).subscribe(
      res => {
        this.userChampionList = res[0].champions;
        this.selectedChampions = [];
      },
      err => {
        this.err = err;
        console.log(err);
      });
  }

  addSelectedChampion(event) {
    let champ = event.option.value;
    if (champ) {
    let index = -1;
    index = this.selectedChampions.findIndex(value => value.name === champ.name);
      if(index < 0) {      
        this.selectedChampions.push(champ); 
        this.championInput.nativeElement.value = '';
      }
    } 
  }

  removeChampion(champ) {
    let i = -1;
    i = this.selectedChampions.findIndex(value => value.name === champ.name );
    if(i > -1)
      this.selectedChampions.splice(i, 1);
  }

  filterChampions(event) {
    const filterValue = event.target.value.toLowerCase();
    return this.userChampionList.filter(option => 
      option.name.toLowerCase().includes(filterValue)
      );
  }

}
