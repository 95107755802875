import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MessageService } from '../../services/message.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  homelist;
  cssClasses = 'col-xs-12 col-sm-6 col-md-3 col-lg-3 p-2';
  constructor(
    private router: Router,
    private messageService: MessageService,
    private spinner: NgxSpinnerService
  ) {
    this.messageService.getMessage().subscribe(message => {
      if (message == this.messageService.searchText) {
        console.log(message);
      }
    });
    this.homelist = [
      { id: '1', title: 'Strategy and Vision', icon: 'explore', route: 'strategyandvision', shortDes: 'Leading the adoption of DevOps practices and tooling across the Coca-Cola system.' },
      { id: '2', title: 'Nurture and Educate', icon: 'model_training', route: 'natureandeducate', shortDes: ' Upskill our developers, software engineers, solutions architects, and technologists in the ways of DevOps.' },
      { id: '3', title: 'Professional Consulting', icon: 'miscellaneous_services', route: 'documentation', shortDes: 'Accelerate DevOps adoption in our Product teams with the help of our Service Reliability Engineers (SRE).' },
      { id: '4', title: 'Support and Operations', icon: 'support', route: '', shortDes: 'Provide tools and technical assistance to developers, including escalations to Microsoft, GitHub, and other partners that we work with to implement DevOps.' },
    ];

    if (this.homelist && this.homelist.length == 2) {
      this.cssClasses = 'col-xs-12 col-sm-6 col-md-6 col-lg-6 p-2';
    } else if (this.homelist && this.homelist.length == 3) {
      this.cssClasses = 'col-xs-12 col-sm-6 col-md-4 col-lg-4 p-2';
    } else if (this.homelist && this.homelist.length == 4) {
      this.cssClasses = 'col-xs-12 col-sm-6 col-md-3 col-lg-3 p-2';
    } else if (this.homelist && this.homelist.length == 1) {
      this.cssClasses = 'col-xs-12 col-sm-12 col-md-12 col-lg-12 p-2';
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  go(route) {
    this.router.navigateByUrl('/' + route);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.spinner.hide();
  }
}
