<section class="section-padding">
    <div class="container">
        <!-- <div class="row">
            <div
                class="col-xs-12 col-sm-12 col-md-12 col-lg-12  wow animate__animated animate__zoomIn animate__delay-0.5s">
                <p>
                    The ability to scale and reach our global development teams is the key principle of our training
                    strategy.To achieve this. we focus on self-paced, online training tools. The variety of training
                    tools and resources will turn you into a certified DevOps expert in no time.
                </p>
            </div>
        </div> -->
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12  wow animate__animated animate__zoomIn animate__delay-0.5s">
                <h4 class="mt-5 mb-0">Online Training</h4>
                <hr class="mt-1">
            </div>
        </div>
        <div class="row">
            <div *ngFor="let message of messagesLern | appFilter: searchText:component" class="col-xs-12 col-sm-6 col-md-6 col-lg-6  wow animate__animated animate__zoomIn animate__delay-0.5s">
                <mat-card>
                <mat-card-header>                   
                    <mat-card-title><img class="learning-img" matListAvatar src="../../../assets/images/books/{{message.img}}"
                        alt="Photo of a Shiba Inu"></mat-card-title>                   
                    <mat-card-subtitle><strong>{{message.subject}}</strong>{{message.content}}</mat-card-subtitle>
                    <mat-card-subtitle><a href="{{message.link}}" target="_blank"><strong>{{message.readMore}}</strong></a><mat-icon class="position-absolute" aria-hidden="false" aria-label="icon">keyboard_arrow_right</mat-icon></mat-card-subtitle>
                </mat-card-header>
            </mat-card>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12  wow animate__animated animate__zoomIn animate__delay-0.5s">
                <h4 class="mt-5 mb-0">Other Resources</h4>
                <hr class="mt-1">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12  wow animate__animated animate__zoomIn animate__delay-0.5s">
                <mat-list>
                    <mat-card *ngFor="let resource of resourceList"
                        class="m-0 wow animate__animated animate__zoomIn animate__delay-0.5s">
                        <mat-card-header>
                            <mat-card-subtitle><mat-icon class="position-absolute mr-2" aria-hidden="false" aria-label="icon">link</mat-icon>&nbsp;&nbsp;<a class="ml-4" href="{{resource.link}}" target="_blank"><strong> {{resource.item}}</strong></a></mat-card-subtitle>                            
                        </mat-card-header>
                    </mat-card>
                </mat-list>
            </div>
        </div>
        <!-- <div class="row mt-4">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <h5>eLEARNING</h5>
                <hr>
                <mat-list>
                    <mat-card *ngFor="let message of messagesLern | appFilter: searchText:component"
                        class="m-0 wow animate__animated animate__zoomIn animate__delay-0.5s">
                        <mat-card-header>
                            <div mat-card-avatar><img matListAvatar src="../../../assets/images/books/{{message.img}}"
                                    alt="Photo of a Shiba Inu"></div>
                            <mat-card-title>{{message.course}}</mat-card-title>
                            <mat-card-subtitle>{{message.subject}}</mat-card-subtitle>
                            <mat-card-subtitle>{{message.content}}</mat-card-subtitle>
                        </mat-card-header>
                    </mat-card>
                </mat-list>

            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <h5>INSTRUCTOR-led</h5>
                <hr>
                <mat-list>
                    <mat-card *ngFor="let message of messagesList | appFilter: searchText: component"
                        class="m-0 wow animate__animated animate__zoomIn animate__delay-0.5s">
                        <mat-card-header>
                            <div mat-card-avatar><img matListAvatar src="../../../assets/images/books/training.png"
                                    alt="Photo of a Shiba Inu"></div>
                            <mat-card-title>{{message.subject}}</mat-card-title>
                            <mat-card-subtitle>{{message.date}}</mat-card-subtitle>
                            <mat-card-subtitle>{{message.duration}}</mat-card-subtitle>
                            <mat-card-subtitle>{{message.instructor}}</mat-card-subtitle>
                        </mat-card-header>
                    </mat-card>
                </mat-list>
            </div>
        </div> -->
    </div>
</section>