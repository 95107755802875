import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class CoeAuthGuard implements CanActivate {
  constructor(private router: Router, private authService: MsalService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (window.localStorage.getItem('msal.idtoken') != null) {
      return true;
    } else {
      this.authService.loginRedirect();
      return false;
    }
  }
}