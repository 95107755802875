import { Component, OnInit, ViewChild } from '@angular/core';
import { ApibundleService } from '../../services/apibundle.service';
import { Router } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from '../../services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-git-all-repositories',
  templateUrl: './git-all-repositories.component.html',
  styleUrls: ['./git-all-repositories.component.css']
})
export class GitAllRepositoriesComponent implements OnInit {
  kbId: string;
  err;
  public allRepositories: any = [];
  username: string = "user";
  norecord: boolean = false;
  public headerToolBar: boolean = false;
  public columns: any;
  displayedColumns: string[] = ['requestorName'];
  limit: number = 5;
  requestDataSourceMobile: any;
  isFilterData: boolean = false;
  @ViewChild(MatPaginator, { static: false }) paginators: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  spinner: boolean = false;
  spinnerName = "allRepo";
  accessTypes: any = ['All', 'Private', 'Public', 'Internal', 'sources', 'Forks', 'Archived', 'Mirrors'];
  languages: any = ['All'];
  searchType: string = 'All';
  searchLanguage: string = '';

  constructor(
    private apibundleService: ApibundleService,
    private commonService: CommonService,
    private authService: MsalService,
    public router: Router,
    private broadcastService: BroadcastService,
    public location: Location
  ) {
    this.broadcastService.subscribe("msal:loginSuccess", payload => {
      const user = this.authService.getAccount().name.split(" ");
      this.username = user[0];
      localStorage.setItem('userName', this.authService.getAccount().userName);
    });
  }

  ngOnInit() {
    this.getAllRepositories();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.requestDataSourceMobile.filter = filterValue;
    if ((this.requestDataSourceMobile.filteredData.length == 0) && filterValue.length > 0)
      this.isFilterData = true;
    else
      this.isFilterData = false;
  }

  doRefresh(event) {
    console.log('Begin async operation');
    this.searchType = 'All';
    this.searchLanguage = '';
    this.getAllRepositories();
    setTimeout(() => {
      console.log('Async operation has ended');
      event.target.complete();
    }, 2000);
  }

  viewRequest(requestDetail) {
      this.router.navigate(['/services/request/view/' + requestDetail.requestType + '/' + requestDetail.id]);
  }
  
  getAllRepositories() {
    this.norecord = false;
    this.spinner = true;
    this.apibundleService.showSpinner('allRepo');
    this.apibundleService.getAllRepositories(this.searchType, this.searchLanguage).subscribe(
      res => {
        this.allRepositories = res;
        if(this.languages.length <= 1) {
          this.languages =  res.map(item => item.language).filter((value, index, self) => self.indexOf(value) === index)
          this.languages.unshift('All');
        }
        this.formatTable();
        //this.allRepositories = this.commonService.sortDescByDate(res);  // sort by date in descending order
        //this.allRepositories = this.commonService.changeDateFormatAndSetPendingStatus(this.requestList); //change date format and set the status t0 Pending  
      },
      err => {
        this.err = err;
        console.log(err);
      });
  }

  getRepoByType(event) {
    this.removeDataFromTable();
    this.searchType = event.value;
    this.getAllRepositories();
  }

  getRepoByLanguage(event) {
    this.removeDataFromTable();
    this.searchLanguage = event.value;
    this.getAllRepositories();
  }

  searchRepo(event) {
    let seachText = event.target.value.toLowerCase();
    if (seachText.length >= 3) {
      this.removeDataFromTable();
      this.norecord = false;
      this.spinner = true;
      this.apibundleService.showSpinner('allRepo');
    this.apibundleService.searchAllRepositories(seachText).subscribe(
      res => {
        this.allRepositories = res;
        this.formatTable();
        //this.allRepositories = this.commonService.sortDescByDate(res);  // sort by date in descending order
        //this.allRepositories = this.commonService.changeDateFormatAndSetPendingStatus(this.requestList); //change date format and set the status t0 Pending  
      },
      err => {
        this.err = err;
        console.log(err);
      });

    }
  }

  formatTable() {
    setTimeout(() => {
      this.requestDataSourceMobile = new MatTableDataSource<any>(this.allRepositories);
      this.requestDataSourceMobile.paginator = this.paginators;
      this.requestDataSourceMobile.sort = this.sort;
      if (this.requestDataSourceMobile.paginator)
        this.requestDataSourceMobile.paginator._intl.itemsPerPageLabel = 'Repositories per page';
      this.apibundleService.hideSpinner('allRepo');
      this.spinner = false;
    }, 2000);
    if (this.allRepositories.length === 0) {
      this.norecord = true;
    } else {
      this.norecord = false;
    }
  }

  removeDataFromTable() {
    this.requestDataSourceMobile = new MatTableDataSource<any>([]);
    this.requestDataSourceMobile.paginator = this.paginators;
    this.requestDataSourceMobile.sort = this.sort;
  }

  redirectToRepoDetail(repoId) {
    this.router.navigateByUrl('services/repoattestation/allrepository/' + repoId); 
  }
  
}
