import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ApibundleService } from '../../services/apibundle.service';
import { MessageService } from '../../services/message.service';


@Component({
  selector: 'app-carsoal',
  templateUrl: './carsoal.component.html',
  styleUrls: ['./carsoal.component.css']
})
export class CarsoalComponent implements OnInit {
  posts: Array<any>;
  slide: any = [];
  latestPost: any = [];
  searchText = '';
  component = '';
  error: boolean = false;
  noRecords: boolean = false;
  errorMsg: string = '';
  spinnerName = "carosal";
  spinner: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apibundleService: ApibundleService,
    private messageService: MessageService,
  ) {
    this.messageService.getMessage().subscribe(message => {
      if (message == this.messageService.searchText) {
        this.searchText = message[0].searchText;
        this.component = message[0].component;
      }
    });
  }

  loadArticlesByTagsAndCategory() {
    this.apibundleService.showSpinner('carosal');
    this.spinner = true;
    let tagId: number = 3;
    let categoryId: number = 2;
    this.apibundleService.getArticlesByTagsAndCategory(tagId, categoryId).subscribe(
      response => {
        this.posts = response;
        if (this.posts.length) {
          this.apibundleService.hideSpinner('carosal');
          let postsSort = this.sorting(this.posts);
          this.latestPost = postsSort.slice(0, 8);
          let slideArr = [];
          if (this.latestPost) {
            var i, j, chunk = 4;
            for (i = 0, j = this.latestPost.length; i < j; i += chunk) {
              slideArr.push(this.latestPost.slice(i, i + chunk));
            }
          }

          this.slide = slideArr;
          this.error = false;
          this.spinner = false;
          this.noRecords = false;
        } else {
          this.spinner = false;
          this.noRecords = true;
        }
      },
      error => {
        console.log(error);
        this.error = true;
        this.errorMsg = "Something went wrong , Please try after some time...";
        this.apibundleService.hideSpinner('carosal');
        this.spinner = false;
      }
    );
  }

  sorting(slide) {
    if (slide) {
      return slide.sort((b, a) => {
        return <any>new Date(a.date) - <any>new Date(b.date);
      });
    }
  }


  ngOnInit(): void {
    this.loadArticlesByTagsAndCategory();
  }

  go(route) {
    this.router.navigateByUrl(route);
  }


}
