import { Component, OnInit } from '@angular/core';
import { ApibundleService } from '../../services/apibundle.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { RequestProjectAccessDetail } from '../../interfaces/RequestProjectDetail.model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-req-view',
  templateUrl: './req-view.component.html',
  styleUrls: ['./req-view.component.css']
})
export class ReqViewComponent implements OnInit {

  ngForm: FormGroup;
  loading: any;
  reqFromUrl: string = "";
  requestDetail = new RequestProjectAccessDetail();
  public disabled: boolean = false;
  public isEnableApprove: boolean = false;
  err: any;
  selectedDevOpsChamp: any = [];
  selectedAdmins: any = [];
  spinner: boolean = false;
  spinnerName = "reqView";
  reqType: string = '';

  constructor(
    private apibundleService: ApibundleService,
    private route: ActivatedRoute,
    public location: Location
  ) {
    this.reqFromUrl = this.route.snapshot.paramMap.get('reqId');
    this.reqType = this.route.snapshot.paramMap.get('reqType');
  }

  ngOnInit() {
    if(this.reqType == "Git")
      this.fetchRequest(this.reqFromUrl);
    else if(this.reqType == "DevOps")
      this.fetchDevOpsRequest(this.reqFromUrl);
  }


  /*--------  FUNCTION TO GET REQUEST USING REQUEST ID -------- */
  async fetchRequest(reqId) {
    this.apibundleService.showSpinner('reqView');
    this.spinner = true;
    this.apibundleService.getRequestById(reqId).subscribe(
      res => {
        this.requestDetail = res;
        this.selectedDevOpsChamp = this.requestDetail.devOpsChampion;
        this.apibundleService.hideSpinner('reqView');
        this.spinner = false;
      },
      err => {
        this.err = err;
        console.log(err);
        this.apibundleService.hideSpinner('reqView');
        this.spinner = false;
      }
    );
  }
  /*--------  END OF FUNCTION -------- */

  async fetchDevOpsRequest(reqId) {
    this.apibundleService.showSpinner('reqView');
    this.spinner = true;
    this.apibundleService.getDevOpsRequestById(reqId).subscribe(
      res => {
        this.requestDetail = res;
        this.selectedDevOpsChamp = this.requestDetail.devOpsChampion;
        this.apibundleService.hideSpinner('reqView');
        this.spinner = false;
      },
      err => {
        this.err = err;
        console.log(err);
        this.apibundleService.hideSpinner('reqView');
        this.spinner = false;
      }
    );
  }

}
