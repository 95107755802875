export class RequestProjectAccessDetail {      
      managerEmail: string = null;     
      invitationId: string = "NAN";     
      managerName: string = null;     
      reason: string = null;     
      reqStatus: string = 'FirstLevelApprovalPending';     
      requestorEmail: string = null;     
      requestorName: string = null;     
      role: string = null;
      secondLevelApprover: string = 'Pending';     
      firstLevelApprover: string = 'Pending';       
      thirdLevelApprover: string = 'Pending';      
      devOpsChampion: any = [];     
      userEmail: string = null;     
      userName: string = null;     
      userType: string = null;     
      groupName: string = null;
      projectName: string = null;
      projectDescription: string = null;
}

