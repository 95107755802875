<div>
  <mat-sidenav-container class="app-container">
    <mat-sidenav #sidenav mode="over" position="end">
      <mat-card class="profile-card border-0 m-0 mt-2">
        <mat-card-header>
          <div mat-card-avatar class="profile-header-avator">
            <img *ngIf="base64String" style="background:#ddd;border-radius: 50%;" src="{{base64String}}"
              alt="profileImage" />
            <img *ngIf="!base64String" style="background:#ddd;border-radius: 50%;" src="../assets/img/avator.png"
              alt="profileImage" />
          </div>
          <mat-card-title class="mb-0">{{user}}</mat-card-title>
          <mat-card-subtitle>{{email}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-nav-list *ngIf="device">
            <a *ngFor="let item of navigationMenu" mat-list-item (click)="sidenav.close()" [routerLink]="[item.route]"
              [routerLinkActive]="['is-active']">
              <mat-icon matListIcon> {{ item.icon }} </mat-icon>
              {{item.title | uppercase}}
            </a>
            <a href="{{forum_menu_link}}" mat-list-item (click)="sidenav.close()" target="_blank"
              [routerLinkActive]="['is-active']">
              <mat-icon matListIcon> person </mat-icon>
              FORUMS
            </a>
          </mat-nav-list>
        </mat-card-content>
      </mat-card>
    </mat-sidenav>
    <mat-sidenav-content class="content">
      <header>
        <section style="background: #000000;border-bottom: 1px solid #7b6f6f;"
          class="{{ isShow ? 'sticky main-header' : 'main-header'}}">
          <div class="container p-0">
            <mat-toolbar class="p-0">
              <mat-toolbar-row class="p-0">
                <button mat-icon-button *ngIf="device" class=" mr-2">
                  <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
                </button>
                <img style="cursor: pointer;" class="logo" src="../assets/img/space-logo.png"
                  (click)="go('');sidenav.close()" />
                <span class="spacer"></span>
                <div *ngIf="!device" id="nav" class="right affix-top">
                  <a *ngFor="let item of navigationMenu" mat-button [routerLink]="[item.route]"
                    [routerLinkActive]="['is-active']">
                    {{item.title | uppercase }}
                  </a>
                  <a href="{{forum_menu_link}}" mat-button target="_blank" [routerLinkActive]="['is-active']">
                    FORUMS
                  </a>
                </div>
                <span class="spacer"></span>
                <button mat-icon-button *ngIf="isLoggedIn" [matMenuTriggerFor]="menu" style="width: 42px;height: 42px;">
                  <img *ngIf="base64String" style="border-radius: 50%;width: 42px;height: 42px;background:#ddd;"
                    src="{{base64String}}" alt="profileImage" />
                  <img *ngIf="!base64String" style="border-radius: 50%;width: 42px;height: 42px;background:#ddd;"
                    src="../assets/img/avator.png" alt="profileImage" />
                </button>
                <mat-menu #menu="matMenu" class="animate__animated animate__zoomIn animate__delay-0.3s">
                  <mat-card class="profile-card border-0 m-0 mt-2">
                    <mat-card-header>
                      <div mat-card-avatar class="profile-header-avator">
                        <img *ngIf="base64String" style="background:#ddd;border-radius: 50%;" src="{{base64String}}"
                          alt="profileImage" />
                        <img *ngIf="!base64String" style="background:#ddd;border-radius: 50%;"
                          src="../assets/img/avator.png" alt="profileImage" />
                      </div>
                      <mat-card-title class="mb-0">{{user}}</mat-card-title>
                      <mat-card-subtitle>{{email}}</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                      <div class="text-left m-2">
                        <button mat-menu-item routerLink="services/myrequests">
                          <mat-icon>shopping_cart</mat-icon>
                          <span>My Requests</span>
                        </button>
                        <button mat-menu-item routerLink="services/approverequest">
                          <mat-icon>check_circle_outline</mat-icon>
                          <span>My Approvals</span>
                        </button>
                        <button mat-menu-item *ngIf="isLoggedIn" (click)="logout()">
                          <mat-icon>logout</mat-icon>
                          <span>Logout</span>
                        </button>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </mat-menu>
              </mat-toolbar-row>
            </mat-toolbar>
          </div>
        </section>
      </header>
      <section>
        <div class="row">
          <div class="col">
            <div style="background-image:url('../assets/img/bg/{{bannerImage}}');"
              class="{{ device ? 'banner-mobile' :'banner'}} {{bannerStyle}}">
              <div *ngIf="bannerText === true && bannerFor === 'home'">
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <app-header [deviceXs]="deviceXs"></app-header>
                    </div>
                  </div>
                </div>
              </div>
              <div class="{{ device ?'banner-text-highlight-mobile':'banner-text-highlight'}}"
                *ngIf="bannerText === true && bannerFor === 'services'">
                <div class="container">
                  <div class="row">
                    <div class="col banner-heading">
                      <div class="banner-content">
                        <h1>our services</h1>
                        <h4 class="mt-2">Request access to GitHub, Azure DevOps, and other tools to improve how you
                          develop and manage your software products.</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="{{ device ?'banner-text-highlight-mobile':'banner-text-highlight'}}"
                *ngIf="bannerText === true && bannerFor === 'training'">
                <div class="container">
                  <div class="row">
                    <div class="col banner-heading">
                      <div class="banner-content">
                        <h1>train smarter</h1>
                        <h4 class="mt-2">Learning DevOps anywhere, anytime on any device. We focus on self-paced, online training tools. Our various training tools and resources will turn a novice to a certified DevOps expert in no time. </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="{{ device ?'banner-text-highlight-mobile':'banner-text-highlight'}}"
                *ngIf="bannerText === true && bannerFor === 'documentation'">
                <div class="container">
                  <div class="row">
                    <div class="col banner-heading">
                      <div class="banner-content">
                        <h1>Technical Documentation</h1>
                        <h4 class="mt-2">
                          Learn about the policies, best practices, how-to guides, processes for everything related to
                          DevOps within Coca-Cola.
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="{{ device ?'banner-text-highlight-mobile':'banner-text-highlight'}}"
                *ngIf="bannerText === true && bannerFor === 'articles'" style="color: #ffffff !important;">
                <div class="container">
                  <div class="row">
                    <div class="col banner-heading">
                      <div class="banner-content">
                        <h1>Learn From Experience</h1>
                        <h4 class="mt-2">
                          Read blogs, success stories, whitepapers, and articles authored by members of the DevOps
                          community to share their experiences and knowledge about DevOps.
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="{{ device ?'banner-text-highlight-mobile':'banner-text-highlight'}}"
                *ngIf="bannerText === true && bannerFor === 'aboutus'">
                <div class="container">
                  <div class="row">
                    <div class="col banner-heading">
                      <div class="banner-content">
                        <h1>about us</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="{{ device ?'banner-text-highlight-mobile':'banner-text-highlight'}}"
                *ngIf="bannerText === true && bannerFor === 'strategyandvision'">
                <div class="container">
                  <div class="row">
                    <div class="col banner-heading">
                      <div class="banner-content">
                        <h1>strategy and vision</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="{{ device ?'banner-text-highlight-mobile':'banner-text-highlight'}}"
                *ngIf="bannerText === true && bannerFor === 'getstarted'">
                <div class="container">
                  <div class="row">
                    <div class="col banner-heading">
                      <div class="banner-content">
                        <h1>get started</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="{{ device ?'banner-text-highlight-mobile':'banner-text-highlight'}}"
                *ngIf="bannerText === true && bannerFor === 'natureandeducate'"
                style="background: #21252929 !important;">
                <div class="container">
                  <div class="row">
                    <div class="col banner-heading">
                      <div class="banner-content">
                        <h1>nurture and educate</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <router-outlet></router-outlet>
      </div>
      <footer class="footer">
        <section>
          <app-footer [deviceXs]="deviceXs"></app-footer>
          <button mat-icon-button *ngIf="isShow" class="dmtop" (click)="gotoTop()">
            <mat-icon class="scroll-icon" style="color:white">keyboard_arrow_up</mat-icon>
          </button>
        </section>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>