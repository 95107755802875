<section class="section-padding" style="min-height: 350px;">
  <div class="container">
    <div class="row" *ngIf="spinner">
      <div class="col">
        <div class="spinnerDivHeight">
          <ngx-spinner [name]="spinnerName" bdColor="transparent" size="default" color="red" type="timer"
            [fullScreen]="false">
            <p style="color: #000;margin-top:50px"> Please wait ... </p>
          </ngx-spinner>
        </div>
      </div>
    </div>
    <div class="row center">
      <div *ngFor="let item of services;let i = index;" [ngClass]="[ i === 0 ? cssClassesCustom  : cssClassesCustom]">
        <mat-card class="article-card wow animate__animated animate__zoomIn animate__delay-0.5s">
          <div mat-card-avatar class="m-auto mb-4 example-header-image" (click)="go(item.route);">
            <mat-icon aria-hidden="false" class="{{item.class}}" aria-label="icon">{{item.icon}}</mat-icon>
          </div>
          <mat-card-header class="services">
            <mat-card-title (click)="go(item.route);">{{item.title}}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <hr>
            <p>
            {{item.desc}}
            </p>
            <!-- <button mat-stroked-button color="warn" (click)="go(item.route);">Read More...</button> -->
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</section>
<!-- <app-login></app-login> -->