import { Component, OnInit, Host, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { ApibundleService } from '../../services/apibundle.service';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { RequestDetail } from '../../interfaces/requestDetail.model';
import { CommonService } from '../../services/common.service';
import { NgxSpinnerService } from 'ngx-spinner'; 

@Component({
  selector: 'app-license-approval',
  templateUrl: './license-approval.component.html',
  styleUrls: ['./license-approval.component.css']
})
export class LicenseApprovalComponent implements OnInit {

  
  err;
  public requestList: any[];  
  norecord:boolean = false;
  approvedList; 
  loadedkbList;  
  isUnAuthorized = false;   
  displayedColumns: string[] = ['environmentName', 'existingEnvironment', 'reqstatus', 'action'];
  limit: number = 5;
  requestDataSourceMobile: any;  
  isFilterData: boolean = false;
  @ViewChild(MatPaginator) paginators: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private apibundleService: ApibundleService,
    private broadcastService:BroadcastService,
    private datePipe: DatePipe,
    private router: Router,
    private authService: MsalService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService
  ) { 
    this.broadcastService.subscribe("msal:loginSuccess", payload => {     
     console.log(payload);
    });
  }

  ngOnInit() {  
    //this.isApprover(); 
    this.fetchRequestForApproval();
    window.scrollTo(0, 0);
  }

  isApprover() {
    this.apibundleService.getUserAuthorization().subscribe(res => {
      if (res.approver)
        this.fetchRequestForApproval();
      else
        this.router.navigate(['/services']);
      this.spinner.hide();
    },
      err => {
        this.err = err;
        this.spinner.hide();
      }
    );
  }

  async approveOrRejectRequest(requestDetail: RequestDetail, status: string){
    var reqObj = {
      "RequestId":"",
      "RequestType":"",
      "RequestStatus":""
    }
    reqObj.RequestId = requestDetail.rowKey;
    reqObj.RequestType = "License";
    reqObj.RequestStatus = status;
    requestDetail.reqstatus = status;
    this.approveOrRjectEnvReq(reqObj);

  }

  async approveOrRjectEnvReq(reqObj: any) {  
    this.spinner.show();
    this.apibundleService.approveOrRjectEnvReq(reqObj).subscribe(
      res => {
        this.requestList = this.requestList.filter(function(req) {
          return req.rowKey != reqObj.RequestId;
      });
        this.formatData();
        this.spinner.hide();
        this.commonService.openSnackBar("License request has been " + reqObj.RequestStatus + " successfully", "OK");
      }, 
      err => {       
        this.err = err; 
        console.log(err);  
        this.spinner.hide(); 
      });

  }
  
  async fetchRequestForApproval() {    
    this.spinner.show();
    this.apibundleService.getAllRequestsToBeApproved().subscribe(
      res => {
        this.requestList = this.commonService.sortDescByDate(res.lRequests);  // sort by date in descending order
        this.requestList = this.commonService.changeDateFormatAndSetPendingStatus(this.requestList); //change date format and set the status to Pending 
        setTimeout(() => {
          this.formatData(); 
          this.spinner.hide();
        }, 2000);
      if(this.requestList.length === 0){
        this.norecord = true;
      } else {
        this.norecord = false;       
      }
      }, 
      err => {       
        this.err = err; 
        console.log(err); 
        this.spinner.hide();
      });
  } 

  formatData() {
    this.requestDataSourceMobile = new MatTableDataSource<any>(this.requestList); 
    this.requestDataSourceMobile.paginator = this.paginators;
    this.requestDataSourceMobile.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.requestDataSourceMobile.filter = filterValue; 
    if((this.requestDataSourceMobile.filteredData.length == 0) && filterValue.length > 0)
      this.isFilterData = true;
    else
      this.isFilterData = false;
  }

  doRefresh(event) {
    console.log('Begin async operation');   
    this.fetchRequestForApproval();
    setTimeout(() => {
      console.log('Async operation has ended');
      event.target.complete();
    }, 2000);
  }

  viewRequest(requestDetail){
      localStorage.setItem('requestDetail', JSON.stringify(requestDetail));
      this.router.navigate(['/viewrequest/'+ 'approver/' + requestDetail.rowkey]); 
  }


}
