<section class="section-padding">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="mat-tab-container animate__animated animate__zoomIn animate__delay-0.5s">
          <mat-table [dataSource]="requestDataSourceMobile" matSort>

            <!-- Name Column -->
            <ng-container matColumnDef="requestorName">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                style="flex-direction: unset;justify-content: left;padding-left:15px;"> REQUESTOR NAME </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.requestorName}} </mat-cell>
            </ng-container>

            <!-- Expires On Column -->
            <ng-container matColumnDef="managerName">
              <mat-header-cell *matHeaderCellDef class="ion-text-uppercase" mat-sort-header> REQUESTED MANAGER </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.managerName}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="requestType">
              <mat-header-cell *matHeaderCellDef class="ion-text-uppercase" mat-sort-header> REQUESTED TYPE </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.requestType}}
              </mat-cell>
            </ng-container>

            <!-- Date Column -->
            <ng-container matColumnDef="timestamp">
              <mat-header-cell *matHeaderCellDef class="ion-text-uppercase" mat-sort-header> DATE CREATED
              </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.timestamp}} </mat-cell>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="reqstatus">
              <mat-header-cell *matHeaderCellDef class="ion-text-uppercase" mat-sort-header> STATUS </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.reqStatus}} </mat-cell>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef> ACTION </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="viewRequest(element)"
                    [ngClass]="{'disabled':checkAccess('view', element.reqstatus) != true}">
                    <mat-icon>info</mat-icon>
                    <span>View</span>
                  </button>
                  
                  <!-- <button [ngClass]="{'disabled':true}" *ngIf="element.requestType == 'License'" mat-menu-item routerLink="/services/license/view/{{element.requestId}}"[ngClass]="{'disabled':checkAccess('view', element.reqstatus) != true}">
                    <mat-icon>info</mat-icon>
                    <span>View</span>
                  </button> -->
                  <!-- <button mat-menu-item [ngClass]="{'disabled':checkAccess('delete', element.reqstatus) === true}">
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                  </button> -->
                </mat-menu>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"></mat-row>
          </mat-table>
          <div class="row" *ngIf="spinner">
            <div class="col">
              <div class="spinnerDivHeight">
                <ngx-spinner [name]="spinnerName" bdColor="transparent" size="default" color="red" type="timer"
                  [fullScreen]="false">
                  <p style="color: #000;margin-top:50px"> Please wait ... </p>
                </ngx-spinner>
              </div>
            </div>
          </div>
          <div class="norecord mt-4" *ngIf="norecord">
            <mat-label class="message">You don't have any Requests. Why not create one!</mat-label>
          </div>
          <div *ngIf="isFilterData" class="mt-4">
            <mat-label class="message">Your search <b>{{this.requestDataSourceMobile.filter}}</b> - did not match any
              Requests
            </mat-label>
          </div>
          <mat-paginator *ngIf="!norecord" #paginatorMobile [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"
            [showFirstLastButtons]="true">
          </mat-paginator>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!spinner">
      <div class="col text-center">
        <div class="norecord">
          <button mat-raised-button color="dark" style="width : 8em;;background-color: #333;color:#fff;"
            (click)="location.back()">Back</button>&nbsp;&nbsp;&nbsp;

        </div>
      </div>
    </div>
  </div>
</section>