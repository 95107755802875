import { Component, OnInit, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { ApibundleService } from '../../services/apibundle.service';
import { Router, NavigationStart } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from '../../services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-req-dashboard',
  templateUrl: './req-dashboard.component.html',
  styleUrls: ['./req-dashboard.component.css']
})
export class ReqDashboardComponent implements OnInit {
  formatDate(arg0: any, arg1: any): any {
    throw new Error("Method not implemented.");
  }

  kbId: string;
  err;
  public requestList: any = [];
  username: string = "user";
  norecord: boolean = false;
  public headerToolBar: boolean = false;
  public columns: any;
  displayedColumns: string[] = ['requestorName', 'managerName', 'requestType', 'timestamp', 'reqstatus', 'action'];
  limit: number = 5;
  requestDataSourceMobile: any;
  isFilterData: boolean = false;
  @ViewChild(MatPaginator, { static: false }) paginators: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  spinner: boolean = false;
  spinnerName = "reqDashboard";

  constructor(
    private apibundleService: ApibundleService,
    private commonService: CommonService,
    private authService: MsalService,
    public router: Router,
    private broadcastService: BroadcastService,
    public location: Location
  ) {
    this.broadcastService.subscribe("msal:loginSuccess", payload => {
      const user = this.authService.getAccount().name.split(" ");
      this.username = user[0];
      console.log(this.authService.getAccount());
      localStorage.setItem('userName', this.authService.getAccount().userName);
    });
  }

  ngOnInit() {
    this.fetchRequests();
  }

  async fetchRequests() {
    this.norecord = false;
    this.apibundleService.showSpinner('reqDashboard');
    this.spinner = true;
    this.apibundleService.getMyRequests().subscribe(
      res => {
        //this.requestList = res;
        this.requestList = this.commonService.sortDescByDate(res);  // sort by date in descending order
        this.requestList = this.commonService.changeDateFormatAndSetPendingStatus(this.requestList); //change date format and set the status t0 Pending  
        setTimeout(() => {
          this.requestDataSourceMobile = new MatTableDataSource<any>(this.requestList);
          this.requestDataSourceMobile.paginator = this.paginators;
          this.requestDataSourceMobile.sort = this.sort;
          if (this.requestDataSourceMobile.paginator)
            this.requestDataSourceMobile.paginator._intl.itemsPerPageLabel = 'Requests per page';
          this.apibundleService.hideSpinner('reqDashboard');
          this.spinner = false;
        }, 2000);
        if (this.requestList.length === 0) {
          this.norecord = true;
        } else {
          this.norecord = false;
        }
      },
      err => {
        this.err = err;
        console.log(err);
        this.apibundleService.hideSpinner('reqDashboard');
        this.spinner = false;
      });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.requestDataSourceMobile.filter = filterValue;
    if ((this.requestDataSourceMobile.filteredData.length == 0) && filterValue.length > 0)
      this.isFilterData = true;
    else
      this.isFilterData = false;
  }

  checkAccess(access: string, status: string) {
    const allowViewAccess = ['Pending', 'Approved'];
    const allowEditAccess = ['Approved'];
    const allowDeleteAccess = ['Approved'];
    if (access === 'view') {
      if (allowViewAccess.includes(status)) {
        return true;
      } else {
        return false;
      }
    }
    if (access === 'edit') {
      if (!allowEditAccess.includes(status)) {
        return true;
      } else {
        return false;
      }
    } else if (access === 'delete') {
      if (!allowDeleteAccess.includes(status)) {
        return true;
      } else {
        return false;
      }
    }
  }

  editRequest(requestId: string, access: string, status: string, requestDetail) {
    if (this.checkAccess(access, status)) {
      localStorage.setItem('requestDetail', JSON.stringify(requestDetail));
      this.router.navigate(['/request/' + requestId]);
      return true;
    }
  }

  doRefresh(event) {
    console.log('Begin async operation');
    this.fetchRequests();
    setTimeout(() => {
      console.log('Async operation has ended');
      event.target.complete();
    }, 2000);
  }

  viewRequest(requestDetail) {
      this.router.navigate(['/services/request/view/' + requestDetail.requestType + '/' + requestDetail.id]);
    }

}
