import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApibundleService } from '../../services/apibundle.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit, OnDestroy {

  services: any = [];
  err: any;
  permissions: any = [];
  error: boolean = false;
  errorMsg: string = '';
  cssClassesCustom: string = '';
  spinnerName = "services";
  spinner = false;
  noRecords: boolean = false;
  cssClasses = 'col-xs-12 col-sm-6 col-md-3 col-lg-3 p-2';

  constructor(
    private router: Router,
    private apibundleService: ApibundleService,
  ) {

  }

  ngOnInit(): void {
    this.addRole();
  }
  addRole() {
    this.apibundleService.showSpinner('services');
    this.spinner = true;
        this.services = [
          { id: '1', class:'first-icon', title: 'GitHub Access Request', icon: 'add_road', route: 'services/github/requestgithubaccess', desc:'Submit a request to be invited to The Coca-Cola organization in GitHub.' },
          { id: '2', class:'second-icon', title: 'Azure DevOps Project', icon: 'how_to_reg', route: 'services/github/requestazuredevopsproject', desc:'Request for an Azure DevOps project to be created.' },
          { id: '3', class:'third-icon', title: 'My Requests', icon: 'school', route: 'services/myrequests', desc:'View all my open, pending and closed requests or requests I created on behalf of somebody else.' },
          { id: '4', class:'last-icon', title: 'Approve Requests', icon: 'book', route: 'services/approverequest', desc:'View all my open, pending and closed requests or requests I created on behalf of somebody else.' },
          { id:'5', class:'first-icon', title:'Github Repositories', icon:'list_alt', route:'services/githubrepositories',  desc:'A directory or storage space where your projects can live'},
          { id:'6', class:'second-icon', title:'Teams', icon:'groups', route:'services/teams', desc:'Among the key functions development team members perform activities throughout the course of the project.'},
          { id:'7', class:'second-icon', title:'Azure DevOps License Request', icon:'receipt', route:'services/licenserequest', desc:'Basic + Test Plans license to use Azure Test Plans for managing my test cases.'},
          { id: '3', class:'third-icon', title: 'All Requests', icon: 'ballot', route: 'services/myrequests', desc:'View all open, pending and closed requests or requests I created on behalf of somebody else.' },
          // {id:'6', title:'Service Request', icon:'settings', route:'service'},
        ];

      this.cssClassesCustom = 'col-xs-12 col-sm-6 col-md-3 col-lg-3 p-2';

      if (this.services && this.services.length == 2) {
        this.cssClasses = 'col-xs-12 col-sm-6 col-md-6 col-lg-6 p-2';
      } else if (this.services && this.services.length == 3) {
        this.cssClasses = 'col-xs-12 col-sm-6 col-md-4 col-lg-4 p-2';
      } else if (this.services && this.services.length == 4) {
        this.cssClasses = 'col-xs-12 col-sm-6 col-md-3 col-lg-3 p-2';
      } else if (this.services && this.services.length == 1) {
        this.cssClasses = 'col-xs-12 col-sm-12 col-md-12 col-lg-12 p-2';
      }
      this.apibundleService.hideSpinner('services');
      this.spinner = false;
  }


  go(route) {
    this.router.navigateByUrl(route);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.apibundleService.hideSpinner('services');
    this.spinner = false;
  }
}