<div class="container">
    <div class="row">
      <div class="col mt-4">
        <!-- <mat-form-field appearance="outline" style="width: 40%;">
          <input matInput placeholder="Find a repository...">
          <mat-icon matPrefix>search</mat-icon>
        </mat-form-field> -->
        <!-- <mat-form-field class="ml-2" appearance="outline" style="width: 20%;">
          <mat-label>Type</mat-label>
          <mat-select matNativeControl [value]="'All'">
            <mat-option *ngFor="let type of accessTypes" [value]="type">
              {{type}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
        <!-- <mat-form-field class="ml-2" appearance="outline" style="width: 20%;">
          <mat-label>Language</mat-label>
          <mat-select matNativeControl [value]="'All'">
            <mat-option *ngFor="let type of languages" [value]="type">
              {{type}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
        <div class="mat-tab-container animate__animated animate__zoomIn animate__delay-0.5s">
          <mat-table [dataSource]="requestDataSourceMobile" matSort>

            <ng-container matColumnDef="requestorName">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                style="flex-direction: unset;justify-content: left;padding-left:15px;"> OWNER REPOSITORIES </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div class="col-md-6">
                      <div class="row mt-4">
                        <label>
                          <h5 (click)="redirectToRepoDetail(element.id)"> {{element.repositoryName}}</h5>
                        </label>
                        <!-- <div class="ml-4">
                          <label class="rounded-corner"> {{element.type}} </label>
                        </div> -->
                      </div>
                      <!-- <div class="row mt-2">
                        <div>
                          <label class="rounded-corner"> Mobile </label>
                        </div>
                        <div class="ml-2">
                          <label class="rounded-corner"> Cep </label>
                        </div>
                      </div> -->
                      <div class="row mt-2 mb-4">
                        <!-- <div>
                          <span class="rounded-circle"></span>
                          <label class="ml-1"> {{element.language != null ? element.language : 'NAN'}} </label>
                        </div> -->
                        <!-- <div class="ml-4">
                          <label class="material-icons" style="font-size: 20px">
                            call_merge
                          </label>
                          <label class="ml-1" style="position:absolute"> 0</label>
                        </div>
                        <div class="ml-4">
                          <label class="material-icons" style="font-size: 20px">
                            star_outline
                          </label>
                          <label class="ml-1" style="position:absolute"> 1</label>
                        </div> -->
                        <div>
                          <label class="material-icons" style="font-size: 20px">
                            info_outline
                          </label>
                          <label class="ml-1" style="position:absolute"> {{element.openissuescount}}</label>
                        </div>
                        <!-- <div class="ml-4">
                          <label class="material-icons" style="font-size: 20px">
                            import_export
                          </label>
                          <label class="ml-1" style="position:absolute"> 4</label>
                        </div> -->
                        <div class="ml-4">
                          <label class="ml-1" style="position:absolute"> Updated at {{element.updatedtime | date :'medium' }}</label>
                        </div>
                      </div>
                    </div>
                  </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"></mat-row>
          </mat-table>
          <div class="row" *ngIf="spinner">
            <div class="col">
              <div class="spinnerDivHeight">
                <ngx-spinner [name]="spinnerName" bdColor="transparent" size="default" color="red" type="timer"
                  [fullScreen]="false">
                  <p style="color: #000;margin-top:50px"> Please wait ... </p>
                </ngx-spinner>
              </div>
            </div>
          </div>
          <div class="norecord mt-4" *ngIf="norecord">
            <mat-label class="message">You don't have any Github Repositories.</mat-label>
          </div>
          <div *ngIf="isFilterData" class="mt-4">
            <mat-label class="message">Your search <b>{{this.requestDataSourceMobile.filter}}</b> - did not match any
              Requests
            </mat-label>
          </div>
          <mat-paginator *ngIf="!norecord" #paginatorMobile [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"
            [showFirstLastButtons]="true">
          </mat-paginator>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!spinner">
      <div class="col text-center">
        <div class="norecord">
          <button mat-raised-button color="dark" style="width : 8em;;background-color: #333;color:#fff;"
            (click)="location.back()">Back</button>&nbsp;&nbsp;&nbsp;

        </div>
      </div>
    </div>
  </div>