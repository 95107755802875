<!-- <section>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
        <mat-card class="article-card animate__animated animate__zoomIn animate__delay-0.5s">
          <div mat-card-avatar class="m-auto mb-2 example-header-image" (click)="go('strategyandvision')">
            <mat-icon aria-hidden="false" aria-label="Strategy and Vision icon">explore</mat-icon>
          </div>
          <mat-card-header class="home">
            <mat-card-title (click)="go('training')">Strategy and Vision</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <hr>
            <p class="text-center">
              Leading the adoption of the Microsoft Power Platform across the Coca-Cola system
            </p>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
        <mat-card class="article-card animate__animated animate__zoomIn animate__delay-0.5s">
          <div mat-card-avatar class="m-auto mb-2 example-header-image" (click)="go('training')">
            <mat-icon aria-hidden="false" aria-label="Nature and Educate icon">model_training</mat-icon>
          </div>
          <mat-card-header class="home">
            <mat-card-title (click)="go('documentation')">Nature and Educate</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <hr>
            <p class="text-center">
              Upskill our digital capabilities by training, educating, and empowering business users
            </p>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
        <mat-card class="article-card animate__animated animate__zoomIn animate__delay-0.5s">
          <div mat-card-avatar class="m-auto mb-2 example-header-image" (click)="go('training')">
            <mat-icon aria-hidden="false" aria-label="Administration and Governance icon">miscellaneous_services
            </mat-icon>
          </div>
          <mat-card-header class="home">
            <mat-card-title (click)="go('services')">Administration and Governance</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <hr>
            <p class="text-center">
              Implement guardrails, policies and processess for users to create with confidence and ease
            </p>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
        <mat-card class="article-card animate__animated animate__zoomIn animate__delay-0.5s">
          <div mat-card-avatar class="m-auto mb-2 example-header-image" (click)="go('training')">
            <mat-icon aria-hidden="false" aria-label="Support and Operations icon">support</mat-icon>
          </div>
          <mat-card-header class="home">
            <mat-card-title (click)="go('services')">Support and Operations</mat-card-title>
          </mat-card-header>       
          <mat-card-content>
            <hr>
            <p class="text-center">
              Provide Level 2/3 support for incidents, requests and manage our relationship with Microsoft
            </p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</section>
<section>
  <app-carsoal></app-carsoal>
</section> -->

<section class="section-padding">
  <div class="container">
    <div class="row">
      <div [ngClass]="cssClasses" *ngFor="let item of homelist;">
        <mat-card class="article-card wow animate__animated animate__zoomIn animate__delay-0.5s">
          <div mat-card-avatar class="m-auto mb-2 example-header-image">
            <mat-icon aria-hidden="false" aria-label="icon">{{item.icon}}</mat-icon>
          </div>
          <mat-card-header class="home">
            <mat-card-title>{{item.title}}</mat-card-title>
            <!-- <mat-card-title (click)="go(item.route);">{{item.title}}</mat-card-title> -->
          </mat-card-header>
          <mat-card-content>
            <hr>
            <p>
              {{item.shortDes}}
            </p>            
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</section>
<section>
  <app-carsoal></app-carsoal>
</section>