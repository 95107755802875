<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col">
                <form class="example-form animate__animated animate__zoomIn animate__delay-0.5s" style="margin:5%;">
                    <h4 *ngIf="reqType=='Git'" style="text-align:left;margin:0px;">VIEW GITHUB REQUEST</h4>
                    <h4 *ngIf="reqType=='DevOps'" style="text-align:left;margin:0px;">VIEW DEVOPS REQUEST</h4>
                    <hr>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Requestor Name</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label>{{requestDetail.requestorName}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Requestor Email</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label>{{requestDetail.requestorEmail}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>User Type</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label>{{requestDetail.userType}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Role</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label>{{requestDetail.role}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Group Name</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label>{{requestDetail.groupName}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Reason</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label>{{requestDetail.reason}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Manager Name</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label>{{requestDetail.managerName}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Manager Email</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label>{{requestDetail.managerEmail}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Request Status</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label>{{requestDetail.reqStatus}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>User Name</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label>{{requestDetail.userName}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>User Email</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label>{{requestDetail.userEmail}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Devops Champion</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-chip-list #chipListCon>
                                <mat-chip *ngFor="let item of selectedDevOpsChamp" [selectable]="true"
                                    [removable]="true">
                                    {{item.name}}
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                    </div>
                    <div class="row" *ngIf="reqType=='DevOps'">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Project Name</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label>{{requestDetail.projectName}}</mat-label>
                        </div>
                    </div>
                    <div class="row" *ngIf="reqType=='DevOps'">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Project Description</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label>{{requestDetail.projectDescription}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-center">
                            <div class="norecord">
                                <button mat-raised-button color="dark" (click)="location.back()"
                                    style="width : 8em;;background-color: #333;color:#fff;">Back</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>