import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApibundleService } from '../../services/apibundle.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { RequestBulkGithubDetail } from '../../interfaces/RequestBulkGithubDetail.model';
import { CommonService } from '../../services/common.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Location } from '@angular/common';

@Component({
  selector: 'app-bulk-github-req',
  templateUrl: './bulk-github-req.component.html',
  styleUrls: ['./bulk-github-req.component.css']
})
export class BulkGithubReqComponent implements OnInit {
  ngForm: FormGroup;
  loading: any;
  reqFromUrl: string = "";
  public title = "";
  public disabled;
  public btnTxt: string = "";
  norecord: boolean = false;
  approvedList;
  requestGithubDetail = new RequestBulkGithubDetail();
  err: any;
  isUserLoading: boolean  = false;
  selectedChampions: any = [];
  @ViewChild('championInput', {static: false}) championInput;
  isSubmitted = false;
  files: any[] = [];
  fileErrorMsg: string = '';
  isManagerDetails: boolean = true;
  
  separatorKeysCodes: number[] = [ENTER, COMMA];
  /*--------  FORM CONTROL - NEED TO CHANGE IN FUTURE-------- */

  userType = new FormControl('', [Validators.required]);
  users = new FormControl('', [Validators.required]);
  userRole = new FormControl('', [Validators.required]);
  userEmail = new FormControl('', [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
  userManager = new FormControl('', [Validators.required]);
  userReason = new FormControl('', [Validators.required]);
  userITGroups = new FormControl('', [Validators.required]);
  userChampion = new FormControl('', [Validators.required]);

  /*--------  END OF FORM CONTROL -------- */

  userTypeList: any = [];
  userList: any = [];
  userRoleList: any = [];
  userITGroupsList: any = [];
  userChampionList: any = [];
  currentValue: string = null;



  constructor(
    private apibundleService: ApibundleService,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: MsalService,
    private broadcastService: BroadcastService,
    private commonService: CommonService,
    public location: Location
  ) {
    this.userTypeList = [
      { id: 1, type: 'Internal' },
      { id: 2, type: 'External' }
    ]
    this.userRoleList = [
      { id: 1, role: 'Developer' },
      { id: 2, role: 'Architect' },
      { id: 3, role: 'Scrum Master' },
      { id: 4, role: 'Product Owner' },
      { id: 5, role: 'Manager' },
      { id: 6, role: 'Director' },
      { id: 7, role: 'Other' }
    ];

    this.fetchITGroups();
  }


  ngOnInit() {
    this.initializeValues();
  }


  initializeValues() {
    this.requestGithubDetail = new RequestBulkGithubDetail();
  }

  async processForm(formData) {
    if(formData.submitted) {
      this.isSubmitted = true;
      this.requestGithubDetail.devOpsChampion = this.selectedChampions;
      this.requestGithubDetail.userName = this.authService.getAccount().name;
      this.requestGithubDetail.userEmail = this.authService.getAccount().userName;
    }
    if (this.isValid()) {
      let requestData = new FormData();
      requestData.append('file', this.files[0], this.files[0].name);
      requestData.append('gitHubDetails', JSON.stringify(this.requestGithubDetail));
      this.apibundleService.createGithubRequest(requestData).subscribe(
        res => {
          this.commonService.openSnackBar("New GitHub access has been created successfully", "OK");
          this.router.navigate(['/services']);
        },
        err => {
          this.err = err;
          console.log(err);
        });
    }
  }

  isValid() {
    for (var key in this.requestGithubDetail) {
      if (this.requestGithubDetail[key] == null || this.requestGithubDetail[key] == '' || this.requestGithubDetail[key].length == 0) {
        if(this.requestGithubDetail.managerName == '') {
          this.userManager.patchValue('');
        } else {
          this.userManager.patchValue(this.requestGithubDetail.managerName);
        }
        return false;
      }
    }
    if(this.fileErrorMsg == '' && this.selectedChampions.length != 0 && this.selectedChampions.length <= 3) {
      return true;
    } else {
      return false;
    }
  }

  getErrorMessage() {
    return 'You must enter a value';
  }

  async getUsers(event) {
    if (event.target.value.length > 2) {
      this.isUserLoading = true;
      this.userList = [];
      var searchData = event.target.value;
      this.apibundleService.getEnvAdmins(searchData).subscribe(
        res => {
          this.userList = res;
          this.isUserLoading = false;
        },
        err => {
          this.err = err;
          this.isUserLoading = false;
          console.log(err);
        });
    }
  }

  setUserType(id) {
    this.requestGithubDetail.userType = id;
    this.requestGithubDetail.managerName = '';
    this.userEmail.setValue('');
    this.userManager.setValue('');
    this.users.setValue('');
    this.requestGithubDetail.managerEmail = '';
  }

  setUserManager(manager) {
    this.requestGithubDetail.managerName = manager.userName;
    this.requestGithubDetail.managerEmail = manager.userEmail;
  }

  
  

  fetchITGroups() {
    this.apibundleService.getGroups().subscribe(
      res => {
        this.userITGroupsList = res;
      },
      err => {
        this.err = err;
        console.log(err);
      });
  }

  fetchChampions(itgroup) {
    this.userChampionList = [];
    this.apibundleService.getChampion(itgroup).subscribe(
      res => {
        this.userChampionList = res[0].champions;
        this.selectedChampions = [];
      },
      err => {
        this.err = err;
        console.log(err);
      });
  }

  addSelectedChampion(event) {
    let champ = event.option.value;
    if (champ) {
    let index = -1;
    index = this.selectedChampions.findIndex(value => value.name === champ.name);
      if(index < 0) {      
        this.selectedChampions.push(champ); 
        this.championInput.nativeElement.value = '';
      }
    } 
  }

  removeChampion(champ) {
    let i = -1;
    i = this.selectedChampions.findIndex(value => value.name === champ.name );
    if(i > -1)
      this.selectedChampions.splice(i, 1);
  }

  filterChampions(event) {
    const filterValue = event.target.value.toLowerCase();
    return this.userChampionList.filter(option => 
      option.name.toLowerCase().includes(filterValue)
      );
  }

  /* start - functions for csv file upload */
  /** on file drop handler **/
  onFileDropped($event) {
    if(this.validateFile($event)) {
      this.files.pop(); //remove existing csv file on drag and drop
      this.prepareFilesList($event);
    } else {
      
    }
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(file) {
    if(this.validateFile(file)) {
      this.fileErrorMsg = '';
      if(this.files.length >= 1)
        this.files.pop(); //remove existing csv file on upload via browse
      this.prepareFilesList(file);
    }
  }

  validateFile(file) {
    if(file.length > 1) {
      this.fileErrorMsg = 'Select a single file';
      return false;
    } else if(!file[0].name.endsWith('.csv')) {
      this.fileErrorMsg = 'Upload file in .csv format';
      return false;
    } else if (file[0].size > 5e+6) {
      this.fileErrorMsg = 'Maximum file size allowed 5000 kb';
      return false;
    }
    return true;
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals?) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  // end - functions for csv file upload

}
