import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApibundleService } from '../../services/apibundle.service';
import { Router, ActivatedRoute } from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-article-details',
  templateUrl: './article-details.component.html',
  styleUrls: ['./article-details.component.css'],
  providers: [ApibundleService],
})
export class ArticleDetailsComponent implements OnInit, OnDestroy {

  post: any;
  postId;
  activeRoute;
  error: boolean = false;
  errorMsg: string = '';
  spinnerName = "articlesdetails";
  spinner = false;
  noRecords: boolean = false;
  environment = environment.WORDPRESS_URL;


  constructor(
    private apibundleService: ApibundleService,
    private route: ActivatedRoute,
  ) { }


  objectSize(obj) {
    var size = 0, key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  async loadArticleDetails(id) {
    this.apibundleService.showSpinner('articlesdetails');
    this.spinner = true;
    (await this.apibundleService.getArticleDetail(id)).subscribe(
      response => {
        this.post = response;
        if (this.objectSize(this.post)) {
          this.apibundleService.hideSpinner('articlesdetails');
          this.spinner = false;
          this.error = false;
          this.noRecords = false;
        } else {
          this.spinner = false;
          this.noRecords = true;
        }
      },
      error => {
        console.log(error);
        this.error = true;
        this.errorMsg = "Something went wrong , Please try after some time...";
        this.apibundleService.hideSpinner('articlesdetails');
        this.spinner = false;
      }
    )
  }

  ngOnInit(): void {
    this.activeRoute = this.route.params.subscribe(params => {
      if (typeof params['id'] != "undefined") {
        this.postId = params['id'];
        this.loadArticleDetails(parseInt(this.postId));
      }
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.apibundleService.hideSpinner('articles');
    this.spinner = false;
  }

}
