import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @ViewChild('searchbar', { static: false }) searchbar: ElementRef;
  searchText = '';
  toggleSearch: boolean = false;


  constructor(
    private messageService: MessageService
  ) { }

  ngOnInit(): void {

  }

  searchKey(event) {
    let searchURL = window.location.pathname;
    console.log(event);
    let searchArr = [{
      searchText: event.target.value,
      component: searchURL,
    }]
    this.messageService.sendMessage(this.messageService.searchText = searchArr);
  }

  openSearch() {
    this.toggleSearch = true;
    this.searchbar.nativeElement.focus();
  }
  searchClose() {
    this.searchText = '';
    this.toggleSearch = false;
  }

}
