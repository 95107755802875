<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="m-auto col-md-10 col-lg-10 col-xs-12 col-sm-12">
                <form class="example-form animate__animated animate__zoomIn animate__delay-0.5s" #form="ngForm"
                    (ngSubmit)="processForm(form)">
                    <h5 class="text-left;m-0;">BULK GITHUB INVITATION REQUEST</h5>
                    <hr class="mb-4">
                    <div class="row mb-4">
                        <div class="col custom-label">
                            <span>Bulk request to be added to the Coca-Cola organization in GitHub</span>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Requested For User
                                Type</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Select Internal if this request for a user with a Coca-Cola email adrress(@coca-cola.com). Otherwise, select external"
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field appearance="outline" style="width: 300px;">
                                <mat-select [(ngModel)]="requestGithubDetail.userType" [formControl]="userType">
                                    <mat-option #userTy *ngFor="let userType of userTypeList" [value]="userType.type"
                                        (onSelectionChange)="setUserType(userTy.value)">
                                        {{userType.type}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="userType.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Role</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Specify the role of the person requesting access to GitHub."
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field appearance="outline" style="width: 300px;">
                                <mat-select [formControl]="userRole" [(ngModel)]="requestGithubDetail.role">
                                    <mat-option *ngFor="let user of userRoleList" [value]="user.role">{{user.role}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="userRole.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Reason</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Specify the reason for requesting access to GitHub.Provide as much detail as possible like the name of the repository and teams that you need to access in GitHub."
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field appearance="outline" style="width: 300px;">
                                <input [(ngModel)]="requestGithubDetail.reason" [formControl]="userReason" matInput>
                                <mat-error *ngIf="userReason.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label">
                            <span>Requested For Manager</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button matTooltip="If internal user type is selected, the Requested For Manager field will use the immediate manager of the user selected in the Requested
                                For Name field, For external user type." aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field appearance="outline" style="width: 300px;">
                                <input [(ngModel)]="requestGithubDetail.managerName" [formControl]="userManager"
                                    matInput [matAutocomplete]="auto" (keyup)="getUsers($event)">
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                    <mat-option *ngFor="let option of userList" [value]="option.userName"
                                        (onSelectionChange)="setUserManager(option)">
                                        {{option.userName}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="userManager.invalid">
                                    {{getErrorMessage()}}</mat-error>
                                <mat-spinner *ngIf="isUserLoading" [diameter]="30"></mat-spinner>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label">
                            <span>IT Group/Tower/Team</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Specify the IT Group/Tower/Team the requester is a part of."
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field appearance="outline" style="width: 300px;">
                                <mat-select [(ngModel)]="requestGithubDetail.groupName" [formControl]="userITGroups">
                                    <mat-option *ngFor="let user of userITGroupsList" [value]="user.group"
                                        (click)="fetchChampions(user.group)">{{user.group}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="userITGroups.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>DevOps Champion</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button matTooltip="Based on the value you selected in IT Group/Tower/Team you can select one or more
                            DevOps Champions who needs to approve your request." aria-label="Purpose">
                                info_outline
                            </mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field appearance="outline" style="width: 300px;">
                                <mat-chip-list #chipList>
                                    <mat-chip *ngFor="let item of selectedChampions" [selectable]="true"
                                        [removable]="true" (removed)="removeChampion($event)">
                                        {{item.name}}
                                        <mat-icon (click)="removeChampion(item)" matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                    <input #championInput [formControl]="userChampion" [matAutocomplete]="auto1"
                                        [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        [matChipInputAddOnBlur]="true" required (keyup)="filterChampions($event)">
                                </mat-chip-list>

                                <mat-autocomplete #auto1="matAutocomplete"
                                    (optionSelected)="addSelectedChampion($event)">
                                    <mat-option *ngFor="let champion of userChampionList" [value]="champion">
                                        {{champion.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-error class="radio-error" *ngIf="isSubmitted && selectedChampions.length == 0">
                                {{getErrorMessage()}}</mat-error>
                            <mat-error class="radio-error" *ngIf="selectedChampions.length > 3">
                                Maximum 3 administrator allowed </mat-error>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label">
                            <span>Upload .CSV File</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Upload .CSV file with name in the first column, and email in the second column. Do not put a header on the first row"
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div appDnd (fileDropped)="onFileDropped($event)" class="border d-inline-grid ">
                                <input type="file"  class="invisible" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
                                <div>
                                    <span  class="material-icons display-4" style="width:50px">
                                    cloud_upload_outline
                                </span>
                                </div>
                                <h6>Drag and drop file here</h6>
                                <h6>or</h6>
                                <label class="but-browse mb-4" for="fileDropRef">
                                    Browse for file
                                </label>
                            </div>
                            <mat-error class="radio-error">
                                {{fileErrorMsg}} </mat-error>
                            <div class="files-list">
                                <div class="single-file" *ngFor="let file of files; let i = index">
                                    <div class="file-icon" style="width: 50px">
                                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                            x="0px" y="0px" viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">
                                            <polygon style="fill:#EDEADA;" points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                                            <g>
                                                <path style="fill:#CEC9AE;"
                                                    d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
                                                <path style="fill:#CEC9AE;"
                                                    d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
                                                <path style="fill:#CEC9AE;"
                                                    d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
                                                <path style="fill:#CEC9AE;"
                                                    d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
                                                <path style="fill:#CEC9AE;"
                                                    d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
                                            </g>
                                            <polygon style="fill:#CEC9AE;" points="37.5,0 37.5,14 51.5,14 " />
                                        </svg>
                                    </div>
                                    <div class="info">
                                        <h4 class="name">
                                            {{ file?.name }}
                                        </h4>
                                        <span class="size">
                                            {{ formatBytes(file?.size) }}
                                        </span>
                                        <app-progress [progress]="file?.progress"></app-progress>
                                    </div>
                            
                                    <div class="delete" (click)="deleteFile(i)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                                            <path fill="#B1B1B1" fill-rule="nonzero"
                                                d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col text-center">
                            <button mat-raised-button color="dark" class="back-button"
                                (click)="location.back()">Back</button>&nbsp;&nbsp;&nbsp;
                            <button mat-raised-button color="warn" style="width : 8em;;">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>