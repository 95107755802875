import { Component, OnInit, ViewChild } from '@angular/core';
import { ApibundleService } from '../../services/apibundle.service';
import { Router } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from '../../services/common.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-git-owner-repositories',
  templateUrl: './git-owner-repositories.component.html',
  styleUrls: ['./git-owner-repositories.component.css']
})
export class GitOwnerRepositoriesComponent implements OnInit {

  kbId: string;
  err;
  public requestList: any = [];
  existUserDetails: any = {};
  username: string = "user";
  norecord: boolean = false;
  public headerToolBar: boolean = false;
  public columns: any;
  displayedColumns: string[] = ['requestorName'];
  limit: number = 5;
  requestDataSourceMobile: any;
  isFilterData: boolean = false;
  @ViewChild(MatPaginator, { static: false }) paginators: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  spinner: boolean = false;
  spinnerName = "reqDashboard";
  accessTypes: any = ['All', 'Private', 'Public', 'Internal', 'sources', 'Forks', 'Archived', 'Mirrors'];
  languages: any = ['All', 'ASP.NET', 'Java', 'Java Script', 'HTML', 'CSS', 'Kotlin', 'PHP', 'Ruby'];

  constructor(
    private apibundleService: ApibundleService,
    private commonService: CommonService,
    private authService: MsalService,
    public router: Router,
    private broadcastService: BroadcastService,
    public location: Location
  ) {
    this.broadcastService.subscribe("msal:loginSuccess", payload => {
      const user = this.authService.getAccount().name.split(" ");
      this.username = user[0];
      console.log(this.authService.getAccount());
      localStorage.setItem('userName', this.authService.getAccount().userName);
    });
  }

  ngOnInit() {
    console.log(window.localStorage.getItem('code'));
    if(window.localStorage.getItem('code') && window.localStorage.getItem('code') != '') {
      this.getGithubOwnerRepo();
    } else {
      this.existingOwnerRepo();
    }
  }

  async existingOwnerRepo() {
    this.norecord = false;
    this.apibundleService.showSpinner('reqDashboard');
    this.spinner = true;
    this.apibundleService.existingOwnerRepo().subscribe(
      res => {
        this.existUserDetails = res;
        if(this.existUserDetails.emailId == null && this.existUserDetails.message == 'user is not exist') {
          window.location.href = environment.GIT_AUTH_URL;
        } else {
          this.getGithubOwnerRepo();
        }
      },
      err => {
        this.err = err;
        console.log(err);
        this.apibundleService.hideSpinner('reqDashboard');
        this.spinner = false;
      });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.requestDataSourceMobile.filter = filterValue;
    if ((this.requestDataSourceMobile.filteredData.length == 0) && filterValue.length > 0)
      this.isFilterData = true;
    else
      this.isFilterData = false;
  }

  doRefresh(event) {
    console.log('Begin async operation');
    //this.fetchRequests();
    setTimeout(() => {
      console.log('Async operation has ended');
      event.target.complete();
    }, 2000);
  }

  redirectToRepoDetail(repoId) {
    this.router.navigateByUrl('services/repoattestation/ownerrepository/' + repoId); 
  }
  
  getGithubOwnerRepo() {
    this.norecord = false;
    this.apibundleService.showSpinner('reqDashboard');
    this.spinner = true;
    this.apibundleService.getGithubOwnerRepo(this.existUserDetails.emailId).subscribe(
      res => {
        this.requestList = res;
        //this.requestList = this.commonService.sortDescByDate(res);  // sort by date in descending order
        //.requestList = this.commonService.changeDateFormatAndSetPendingStatus(this.requestList); //change date format and set the status t0 Pending  
        setTimeout(() => {
          this.requestDataSourceMobile = new MatTableDataSource<any>(this.requestList);
          this.requestDataSourceMobile.paginator = this.paginators;
          this.requestDataSourceMobile.sort = this.sort;
          if (this.requestDataSourceMobile.paginator)
            this.requestDataSourceMobile.paginator._intl.itemsPerPageLabel = 'Repositories per page';
          this.apibundleService.hideSpinner('reqDashboard');
          this.spinner = false;
        }, 2000);
        if (this.requestList.length === 0) {
          this.norecord = true;
        } else {
          this.norecord = false;
        }
      },
      err => {
        this.err = err;
        console.log(err);
      });
  }

  ngOnDestroy() {
    window.localStorage.setItem('code', '');
    window.localStorage.setItem('state', '');
  }
}
