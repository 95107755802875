import { Component, OnInit, Host, ViewChild } from '@angular/core';
import { ApibundleService } from '../../services/apibundle.service';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { Location } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-team-attestation',
  templateUrl: './team-attestation.component.html',
  styleUrls: ['./team-attestation.component.css']
})
export class TeamAttestationComponent implements OnInit {
  err;
  activeRoute: any;
  norecord: boolean = false;
  approvedList;
  loadedkbList;
  isUnAuthorized = false;
  spinner: boolean = false;
  spinnerName = "reqApproval";
  teamDetail: any = {};
  teamId: any;
  statusList: any = ['Active', 'Inactive'];
  certStatus = new FormControl('');
  dateCerti = new FormControl(new Date());
  comments = new FormControl('');
  ngForm: FormGroup;
  teamPage: string;
  isDisable: boolean = false;
  commentArray: any = [];
  monthAccess: number[] = [1, 5, 9];
  grantAccess: boolean = false;
  constructor(
    private apibundleService: ApibundleService,
    private broadcastService: BroadcastService,
    private router: Router,
    public location: Location, private route: ActivatedRoute,
    private commonService: CommonService,
    private authService: MsalService,
  ) {
    this.broadcastService.subscribe("msal:loginSuccess", payload => {
      console.log(payload);
    });
  }

  ngOnInit() {
    this.activeRoute = this.route.params.subscribe(params => {
      if (typeof params['teamId'] != "undefined") {
        this.teamId = params['teamId'];
        this.teamPage = params['teamPage'];
        this.fetchTeamDetail();
      }
    });
    if(this.teamPage == "allteams") {
        this.isDisable = true;
    } 
    if(this.monthAccess.includes(new Date().getMonth()))
      this.grantAccess = true;
      window.scrollTo(0, 0);
  }

  async fetchTeamDetail() {
    this.apibundleService.showSpinner('reqApproval');
    this.spinner = true;
    this.apibundleService.fetchTeamDetail(this.teamId).subscribe(
      res => {
        this.teamDetail = res;
        if(this.teamDetail.status || this.teamDetail.status == '') {
          this.teamDetail.status = 'Inactive'
          this.certStatus.setValue('Inactive');
        }
        this.dateCerti.setValue(new Date(this.teamDetail.dateOfCertification));
        this.sortCommentByDate();
        this.apibundleService.hideSpinner('reqApproval');
        this.spinner = false;
        if (this.teamDetail.length === 0) {
          this.norecord = true;
        } else {
          this.norecord = false;
        }
      },
      err => {
        this.err = err;
        console.log(err);
        this.apibundleService.hideSpinner('reqApproval');
        this.spinner = false;
      });
  }

  sortCommentByDate() {
    this.teamDetail.comments.sort((a, b) => {
      return <any>new Date(b.commentDate) - <any>new Date(a.commentDate);
    });
  }

  async processForm(formData) {
    if(this.isDisable && this.comments.value != '') {
      this.submitComment();
    } else if(this.isDisable) {
      this.commonService.openSnackBar("Please add comments", "OK");
    }
    if(!this.isDisable && this.teamDetail.status != this.certStatus.value){
      this.submitAttestation();
    } else if(!this.isDisable) {
      this.commonService.openSnackBar("Please change the status", "OK");
    }
  }

  submitComment() {
    var commentsDetail: any = {};
    var comment: any = {
        "comment": this.comments.value,
        "id": this.teamDetail.teamId,
        "commentType": "teams",
        "commentedBy": this.authService.getAccount().userName
    };
    commentsDetail.id = this.teamDetail.teamId;
    commentsDetail.comments = [comment];
      this.apibundleService.submitComment(commentsDetail).subscribe(
        res => {
          this.commonService.openSnackBar("Comment has been added sucessfully successfully", "OK");
          this.router.navigate(['/services/teams']);
        },
        err => {
          this.err = err;
          console.log(err);
        });
  }

  submitAttestation() {
    var comment: any = {
      "comment": this.comments.value,
      "id": this.teamDetail.teamId,
      "commentType": "teams",
      "commentedBy": this.authService.getAccount().userName
  };
    var teamAttesDetails: any = {
    "teamId": this.teamDetail.teamId,
    "teamName": this.teamDetail.teamName,
    "status": this.certStatus.value,
    "comments": [comment],
    "attestedByUserName": this.authService.getAccount().name,
    "attestedByUserEmail": this.authService.getAccount().userName,
    };
      this.apibundleService.submitAttestation(teamAttesDetails).subscribe(
        res => {
          this.commonService.openSnackBar("Comment has been added sucessfully successfully", "OK");
          this.router.navigate(['/services/githubrepositories']);
        },
        err => {
          this.err = err;
          console.log(err);
        });
  }

  doRefresh(event) {
    console.log('Begin async operation');
    this.fetchTeamDetail();
    setTimeout(() => {
      console.log('Async operation has ended');
      event.target.complete();
    }, 2000);
  }
}
