import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, OnDestroy, HostListener, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ApibundleService } from '../../services/apibundle.service';
import { ActivatedRoute } from '@angular/router';


/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface FoodNode {
  name: string;
  link: string;
  children?: FoodNode[];
  parentId: number;
  id: number;
}
// interface FoodNode {
//   name: string;
//   children?: FoodNode[];
// }


/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  link: string;
  id: number;
}


@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.css']
})
export class DocumentationComponent implements OnInit {
  userData: any = [];
  isShow: boolean;
  topPosToStartShowing = 100;
  userList: any = [];
  scrollHeight: any;
  daocData: any;
  docMenu: any;
  docMenuList: any;
  menuArray: FoodNode[] = [];
  breadCrumnArray: any = [];
  searchArrayList: any = [];
  highlight: string = '';
  parent = [];
  nextParent = [];
  children = [];

  private _transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      link: node.link,
      parentId: node.parentId,
      level: level,
      id: node.id
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  constructor(private apibundleService: ApibundleService, private route: ActivatedRoute, private render: Renderer2) {
    //this.dataSource.data = TREE_DATA;
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;


  ngOnInit(): void {
    this.getGitHub();
  }


  /*--------  FUNCTION TO GET GITHUB DOCUMENTS-------- */
  getDoc(node) {
    this.treeControl.expandAll();   
    var el = document.querySelectorAll('.hightlight');
    for (var i = 0; i < el.length; i++) {
      var currentEl = el[i];
      currentEl.removeAttribute('style');
    } 
    // let nodeName = this.treeControl.dataNodes.forEach(element => {
    //   node = node
    //   if (node.link == undefined && element.expandable == true) {
    //       return node[node.id+1];     
    //   }
    // })
    let id = 'highlight-' + node.name;
    var menuActive = (document.getElementById(id) as HTMLElement);
    if (menuActive)
      menuActive.style.fontWeight = 'bold';
    this.searchArrayList = [];
    this.setBreadcrumbNavigation(node);
    //this.treeControl.collapseAll();
    // this.treeControl.expand(this.treeControl.dataNodes[node.parentId]);
    // this.treeControl.expand(this.treeControl.dataNodes[node.id + 1]);
    if (node.expandable !== true) {
      this.apibundleService.getGitHubDetails(node.link).subscribe(
        res => {
          this.daocData = res.content;
          console.log(this.daocData);
        },
        err => {
          // this.err = err;
          // console.log(err);
        });
    }
  }
  /*--------  END OF FUNCTION -------- */


  setBreadcrumbNavigation(node) {
    this.breadCrumnArray = [];
    let brdCrmb = this.pathTo(this.menuArray, node.name);
    let rebuildBrdCrmb = brdCrmb.split('.');
    let breadcrumb = [];
    rebuildBrdCrmb.forEach(element => {
      breadcrumb.push(element);
    });
    this.breadCrumnArray = this.treeControl.dataNodes.filter(function (n) {
      return rebuildBrdCrmb.some(function (name) {
        return n.name === name
      });
    })
  }

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.scrollHeight = scrollPosition;
    if (scrollPosition > 350) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }


  pathTo(array, target) {
    var result;
    array.some(({ name, children = [] }) => {
      if (name === target) {
        return result = name.trim();
      }
      var temp = this.pathTo(children, target)
      if (temp) {
        return result = name.trim() + '.' + temp.trim();
      }
    });

    return result;
  };


  //console.log(findDeep(activityItems, 'scuba diving'))
  searchDocsMenu(event) {
    let seachText = event.target.value.toLowerCase();
    this.searchArrayList = [];
    if (seachText.length > 0) {
      var matchObj: any = {};
      this.docMenuList.forEach((element, index) => {
        if (element.match(/\[(.*?)\]/) && element.toLowerCase().includes(seachText)) {
          matchObj = {};
          matchObj.name = element.match(/\[(.*?)\]/)[1];
          matchObj.id = index;
          matchObj.link = element.match(/\((.*)\)/)[1].replace('./', '');
          this.searchArrayList.push(matchObj);
        }
      });
    }
  }

  getGitHub() {
    this.apibundleService.getGitHub().subscribe(
      response => {
        this.docMenu = response.content;
        this.generateMenu();
      },
      error => {
        if (error.status === 200) {
          console.log(error);
        }
      }
    )
  }

  generateMenu() {
    this.docMenu = this.docMenu.split('\n');
    this.menuArray = [];
    var menuObj: any = {};
    var childrenObj: any = {};
    var childrenArray: any = [];
    var nodeDetails: any = {};
    var menuLevel: number = 0;
    this.docMenuList = this.docMenu.filter((el) => {
      return el != "";
    });
    this.docMenuList.forEach((item, index) => {
      if ((item.match(/#/g) || []).length == 1) {
        if (Object.keys(menuObj).length != 0)
          this.menuArray.push(menuObj);
        childrenArray = [];
        menuObj = {};
        menuObj.name = item.replace('# ', '');
        menuObj.id = index;
        menuLevel = 1;
      } else if ((item.match(/#/g) || []).length == 2) {
        childrenObj.name = item.trim().replace('## ', '');
        childrenObj.parentId = menuObj.id;
        childrenObj.id = index;
        childrenObj.children = [];
        childrenArray.push(childrenObj);
        childrenObj = {};
        menuLevel = 2;
      } else if ((item.match(/#/g) || []).length == 3) {
        let tempObj: any = [];
        childrenObj.name = item.replace('### ', '');
        childrenObj.parentId = menuObj.id;
        childrenObj.id = index;
        childrenObj.children = [];
        tempObj = childrenObj;
        childrenArray[childrenArray.length - 1].children.push(tempObj);
        childrenObj = {};
        menuLevel = 3;
      } else if ((item.match(/#/g) || []).length == 4) {
        let tempObj: any = [];
        childrenObj.name = item.replace('#### ', '');
        childrenObj.parentId = menuObj.id;
        childrenObj.id = index;
        childrenObj.children = [];
        tempObj = childrenObj;
        childrenArray[index - 2].children[index - 1].children.push(tempObj);
        childrenObj = {};
        menuLevel = 4;
      } else if ((item.match(/#/g) || []).length == 5) {
        let tempObj: any = [];
        childrenObj.name = item.trim().replace('##### ', '');
        childrenObj.parentId = menuObj.id;
        childrenObj.id = index;
        childrenObj.children = [];
        tempObj = childrenObj;
        childrenArray[index - 3].children[index - 2].children[index - 1].children.push(tempObj)
        childrenObj = {};
        menuLevel = 5;
      } else if (!item.startsWith('\n') && item.length > 0 && item.match(/\[(.*?)\]/) && item.match(/\((.*)\)/)) {
        childrenObj.name = item.match(/\[(.*?)\]/)[1];
        childrenObj.parentId = menuObj.id;
        childrenObj.id = index;
        childrenObj.link = item.match(/\((.*)\)/)[1].replace('./', '');
        if (Object.keys(nodeDetails).length == 0) {
          nodeDetails.link = childrenObj.link;
          nodeDetails.parentId = childrenObj.parentId;
          nodeDetails.name = childrenObj.name;
        }
        if (menuLevel == 5) {
          childrenArray[childrenArray.length - 1]
            .children[childrenArray[childrenArray.length - 1].children.length - 1]
            .children[childrenArray[childrenArray.length - 1].children[childrenArray[childrenArray.length - 1].children.length - 1].children.length - 1]
            .children[childrenArray[childrenArray.length - 1].children[childrenArray[childrenArray.length - 1].children[childrenArray[childrenArray.length - 1].children.length - 1].children.length - 1]].children.push(childrenObj);
        } else if (menuLevel == 4) {
          childrenArray[childrenArray.length - 1]
            .children[childrenArray[childrenArray.length - 1].children.length - 1]
            .children[childrenArray[childrenArray.length - 1].children[childrenArray[childrenArray.length - 1].children.length - 1].children.length - 1].children.push(childrenObj);
        } else if (menuLevel == 3) {
          childrenArray[childrenArray.length - 1]
            .children[childrenArray[childrenArray.length - 1].children.length - 1].children.push(childrenObj);
        } else if (menuLevel == 2) {
          childrenArray[childrenArray.length - 1]
            .children.push(childrenObj);
        } else if (menuLevel == 1) {
          childrenArray.push(childrenObj);
        }
        menuObj.children = childrenArray;
        childrenObj = {};
      }
      if (index + 1 == this.docMenuList.length) {
        menuObj.children = [];
        this.menuArray.push(menuObj);
      }
    });
    this.dataSource.data = this.menuArray;
    this.getDoc(nodeDetails);
  }


}
