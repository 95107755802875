import { Component, OnInit, Input, ViewChild, Self, Optional } from '@angular/core';
import { ApibundleService } from '../../services/apibundle.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';

export class RequestGithubDetail {
  requestorName: string = null;
}

@Component({
  selector: 'app-req-environment',
  template: `
   <form #form="ngForm">    
    <div class="container">
      <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Requested For
                  Name</span>
              <span class="required-asterisk">*</span>
              <mat-icon mat-raised-button matTooltip="The name of the person requesting access to GitHub.If internal user type is selected you can 
                  serach for the person in the list.If external user type is selected you need to manually
                  type in the name of person requesting access." aria-label="Purpose">info_outline
              </mat-icon>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <mat-form-field appearance="outline" style="width: 300px;">
                  <input type="email" matInput [(ngModel)]="requestGithubDetail.requestorName"
                      [formControl]="users" [matAutocomplete]="auto" (keyup)="getUsers($event)">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                      <mat-option *ngFor="let option of userList" [value]="option.userName">                                     
                          {{option.userName}}
                      </mat-option>
                  </mat-autocomplete>
                  <mat-spinner *ngIf="isUserLoading" [diameter]="30"></mat-spinner>
              </mat-form-field>
              <mat-error *ngIf="users.invalid">{{getErrorMessage()}}</mat-error>
          </div>
      </div>            
    </div>
  </form>
  `,
  styles: [`
  .mat-form-field {
    display: inline;
    position: relative;
    text-align: left;
  }
  
  .mat-icon {
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    color: #aaa;
    height: 24px;
    width: 24px;
    position: relative;
    top: 6px;
    left: 5px;
    cursor: pointer;
    font-size: 20px;
  }
  
  .input-row .custom-label {
    width: 28vw;
    flex: none !important;
  }
  
  /* Landscape phones and portrait tablets */
  
  @media (max-width: 767px) {
    .input-row .custom-label {
      width: 80vw;
      flex: none !important;
    }
  }
  
  .md .custom-label {
    margin-bottom: 4px;
    font-size: 13px !important;
    align-self: stretch;
    width: auto;
    max-width: 100%;
    --color: initial;
    display: block;
    color: var(--color);
    font-family: var(--ion-font-family, inherit);
    font-size: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 27px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .ios .custom-label {
    margin-bottom: 4px;
    font-size: 13px !important;
    align-self: stretch;
    width: auto;
    max-width: 100%;
    --color: initial;
    display: block;
    color: var(--color);
    font-family: var(--ion-font-family, inherit);
    font-size: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 25px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .custom-padding {
    padding: 16px 0px 16px 0px;
  }
  
  .disable {
    cursor: default;
    opacity: .4;
    pointer-events: none;
  }
  
  .swiper-slide {
    display: block;
  }
  
  .swiper-slide h2 {
    margin-top: 2.8rem;
  }
  
  .swiper-slide img {
    max-height: 50%;
    max-width: 80%;
    margin: 60px 0 40px;
    pointer-events: none;
  }
  
  b {
    font-weight: 500;
  }
  
  p {
    padding: 0 40px;
    font-size: 14px;
    line-height: 1.5;
    color: var(--ion-color-step-600, #60646b);
  }
  
  p b {
    color: var(--ion-text-color, #000000);
  }
  
  .disabled {
    cursor: default;
    opacity: .4;
    pointer-events: none;
  }
  
  .content {
    display: flex;
  }
  
  .radio-space {
    padding-bottom: 20px;
  }
  
  .mat-spinner {
    margin-top: 20px;
  }
  
  .radio-error {
    font-size: 75% !important;
    margin-top: 5px;
    margin-left: 14px;
  }
  
  .mat-chip-remove {
    right: 0 !important;
    margin-right: 5px !important;
    position: static;
  }
  
  .mat-chip {
    padding-right: 21px !important;
  }
  `]
})
export class ReqEnvironmentComponent implements OnInit {
  ngForm: FormGroup;
  loading: any;
  requestGithubDetail = new RequestGithubDetail();
  err: any;
  isUserLoading: boolean = false;
  users = new FormControl('', [Validators.required]);
  @Input() userList: Array<any>;
  @Input() searchStartFrom = 2;

  constructor(
    private apibundleService: ApibundleService,
    public location: Location
  ) { }

  ngOnInit() {
    this.initializeValues();
  }


  initializeValues() {
    this.requestGithubDetail = new RequestGithubDetail();
  }

  // async processForm(formData) {
  //   if(formData.submitted) {
  //     this.isSubmitted = true;
  //     this.requestGithubDetail.devOpsChampion = this.selectedChampions;
  //     this.requestGithubDetail.userName = this.authService.getAccount().name;
  //     this.requestGithubDetail.userEmail = this.authService.getAccount().userName;
  //   }
  //   if (this.isValid()) {
  //     this.apibundleService.createGithubRequest(this.requestGithubDetail).subscribe(
  //       res => {
  //         this.commonService.openSnackBar("New GitHub access has been created successfully", "OK");        
  //       },
  //       err => {
  //         this.err = err;
  //         console.log(err);
  //       });
  //   }
  // }

  isValid() {
    for (var key in this.requestGithubDetail) {
      if (this.requestGithubDetail[key] == null || this.requestGithubDetail[key] == '' || this.requestGithubDetail[key].length == 0) {
        return false;
      }
    }
  }

  getErrorMessage() {
    return 'You must enter a value';
  }

  validate() {
    for (var key in this.requestGithubDetail) {
      if (this.requestGithubDetail[key] == null || this.requestGithubDetail[key] == '' || this.requestGithubDetail[key].length == 0) {
        return false;
      }
    }
    return true;
  }

  async getUsers(event) {
    if (event.target.value.length > this.searchStartFrom) {
      this.isUserLoading = true;
      this.userList = [];
      var searchData = event.target.value;
      this.apibundleService.getEnvAdmins(searchData).subscribe(
        res => {
          this.userList = res;
          this.isUserLoading = false;
        },
        err => {
          this.err = err;
          this.isUserLoading = false;
          console.log(err);
        });
    }
  }

}
