import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css']
})
export class TeamsComponent implements OnInit {

  public isOwnerTabSelected: boolean = false;
  selectedTab: any = 0;
  constructor() { }

  ngOnInit(): void {
  }

  enableOwnerRepoTab(event) {
    if(event.index == 1)
      this.isOwnerTabSelected = true;
    else    
      this.isOwnerTabSelected = false;
  }

}
