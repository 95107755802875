export class RequestDetailRequest {
      PartitionKey: string = 'SBDEnvTest';
      RowKey: string = 'NAN';
      Admin: any;
      BU: string = '';
      CDS: string = '';
      Connectors: any;
      Currency: string = '';
      EnvironmentLandscape: string = 'Not Applicable';
      userType: string = '';
      ExistingEnvironment: string = 'No';
      IPPAcceptance: string = '';
      user: string = '';
      Region: string = '';
      Reqstatus: string = 'New';
      Restrictedinformation: string = '';
      Username: string = '';
      Environmentid: string = 'NAN';
      EnvironmentName: string = '';
      Interactedvia: string = 'Application';
}
    
